import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import status from "../constants/actionStatus";
import { getSelfLearningAction } from "../actions/getSelfLearningAction";

/**
 * get list of all category.
 * @returns {*}
 */
export default function useSelfLearning(courseId) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSelfLearningAction(courseId));
    }, [ courseId ]);
    let learningData = useSelector((state) => state.selfLearningReducers);
    let isLoadingSelf = learningData.status === status.PENDING;
    let selfLearningData = learningData.selfLearningData;
    return {
        isLoadingSelf,
        selfLearningData,
    };
}
