import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import pathToRegexp from "path-to-regexp";
import "../Profile/profile.scss";
import { Box } from '@material-ui/core';

/**
 * Navigation
 * @returns {*}
 * @constructor
 */
const Navigation = ({
  children,
  openSidebar,
  windowScreenWidth,
  screenWidth,
  closeDrawer,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isFirefox = typeof InstallTrigger !== "undefined";
  const currentPath = location.pathname;

  useEffect(() => {
    // To start listening for location changes...
    let unListen = history.listen((obj) => {
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(obj.pathname);
      }
    });
    return () => {
      return unListen();
    };
  }, [history]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const validPaths = ["/dashboard", "/certificate-list"];
  const handledPath = [
    "/support",
    "/surveys",
    "/my-courses",
    "/courses",
    "/notifications",
    "/reports",
    "/course-material/content",
    "/support-ticket",
  ];

  useEffect(() => {
    if (isFirefox) {
      if (
        validPaths.includes(currentPath)) {
        const mainDivs = document.querySelectorAll(".firefox-browser");
        mainDivs.forEach((mainDiv) => {
          mainDiv.style.height = "100vh";
          mainDiv.style.paddingBottom = "80px";
        });
      } else if (handledPath.includes(currentPath)) {
        const mainDivs = document.querySelectorAll(".firefox-browser");
        mainDivs.forEach((mainDiv) => {
          mainDiv.style.height = "100vh";
        });
      } else {
        const mainDivs = document.querySelectorAll(".firefox-browser");
        mainDivs.forEach((mainDiv) => {
          mainDiv.style.paddingBottom = "0px";
        });
      }
    }
  }, [isFirefox, currentPath]);

  useEffect(() => {
    let title = "";
    if (pathToRegexp("/quiz/:mode/:slug").exec(location.pathname)) {
      const name = capitalize(
        pathToRegexp("/quiz/:mode/:slug")
          .exec(location.pathname)[2]
          .replace(/-/g, " ")
      );
      title = `${name} | LMS`;
    } else {
      switch (location.pathname) {
        case "/dashboard":
          title = "Dashboard | LMS";
          break;
        case "/courses":
          title = "Courses | LMS";
          break;
        case "/my-courses":
          title = "My Courses | LMS";
          break;
        case "/my-profile":
          title = "My Profile | LMS";
          break;
        case "/course-material/content":
          title = "Self Learning | LMS";
          break;
        case "/certificate-list":
          title = "My Certificates | LMS";
          break;
        case "/notifications":
          title = "My Notifications | LMS";
          break;
        case "/surveys":
          title = "Surveys | LMS";
          break;
        case "/support":
          title = "New Support Ticket | LMS";
          break;
        case "/support-ticket":
          title = "Support Ticket | LMS";
          break;
        case "/reports":
          title = "Reports | LMS";
          break;
        default:
          title = "LMS";
      }
    }
    document.title = title;
  }, [location]);

  const styles = {
    firefoxBrowser: (isFirefox) => ({
      height:
        isFirefox &&
        (validPaths.includes(currentPath) || handledPath.includes(currentPath))
          ? "100vh"
          : "",
      paddingBottom:
        isFirefox && validPaths.includes(currentPath) ? "80px" : "0px",
    }),
  };
  const classes = useStyles(styles)();

  return (
    <Box
      style={{ overflow: "auto", height: "-webkit-fill-available" }}
      className={`container ${openSidebar ? "open-drawer" : ""} ${isFirefox ? `${classes.firefoxBrowser}` : ""}`}
      onClick={windowScreenWidth <= screenWidth ? closeDrawer.bind(this) : () => null}
    >
      {children}
    </Box>
  );
};

export default Navigation;
