import CUserImageIcon from "../CUserImageIcon";
import React, { useState, useEffect } from "react";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { Box } from "@material-ui/core";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { useDispatch, useSelector } from "react-redux";
import NotificationBar from "../common/Header/NotificationBar";
import { useSearchQuery } from "../../services/SearchQueryContext";
import {
  getUser,
  updateProfileImageDetails,
} from "examsbook-react-sdk/actions/getUser";
import editIcon from "../../assets/images/editIcon.png";
import defaultImage from "../../assets/images/student.png";
import { useTheme } from "../../constants/themeProvider";
import EditIcon from "@material-ui/icons/Edit";
import { ColorsConstant } from "../../constants/colorConstant";
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

function UserProfile() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const t = useTranslations();
  const [errorMessage, setErrorMessage] = useState(null);
  const theme = useTheme();

  const { setNewFlag } = useSearchQuery();
  useEffect(() => {
    setNewFlag(true);
    return () => setNewFlag(false);
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        setErrorMessage(t("Please upload profile image less than 1 MB"));
        return;
      }
      const fileType = file.type;
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validImageTypes.includes(fileType)) {
        setErrorMessage(
          t("Invalid file type. Only jpeg, jpg and png images are allowed")
        );
        return;
      }
      setErrorMessage(null);

      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const res = await dispatch(
            updateProfileImageDetails(user?._id, e.target.result, file.name)
          );
          if (res) {
            dispatch(getUser());
          }
        } catch (error) {
          console.error("Error updating profile image:", error.message);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 767);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    fragment: {
      background: "#FAFAFA",
    },
    editIconAlign: {
      height: "40px !important",
      width: "40px !important",
      cursor: "pointer",
      border: "0px !important",
    },
    header: {
      display: "flex",
      position: "sticky",
      top: "0px",
    },
    title: {
      marginTop: 9,
      padding: "0px 26px 15px 20px",
      color: "#202020",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: "normal",
    },
    userProfile: {
      display: "flex",
      justifyContent: "center",
      width: 333,
      height: 333,
      borderRadius: 30,
      background: "rgba(12, 52, 80, 0.08)",
      position: 'relative',
      '& svg': {
        position: 'absolute',
        zIndex: 1,
        top: 15,
        left: 15
      },
      "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain",
        borderRadius: 30,
      },
      '@media (max-width: 767px)': {
        width: 250,
        height: 250
      }
    },
    fileUploadMsg: {
      color: "red",
      textAlign: "center",
      paddingTop: "10px",
    },
    profileSection: {
      width: "100%",
      border: "1px solid #D7D7D7",
      boxShadow: "1px 1px 26px 0px rgba(132, 129, 138, 0.30)",
      borderRadius: "6px",
      display: "flex",
      columnGap: 40,
      padding: 30,
      backgroundColor: ColorsConstant.white,
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        rowGap: 30,
        padding: '30px 15px'
      }
    },
    wrapper: {
      padding: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (min-width: 280px) and (max-width: 767px)": {
        paddingTop: 100,
        paddingBottom: 0,
      },
    },
    userName: {
      color: "#000",
      textAlign: "center",
      fontSize: "25px",
      fontWeight: 600,
      paddingTop: "20px",
    },
    location: {
      color: "#84818A",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 300,
      paddingBottom: 24,
    },
    inputBox: {
      display: "flex",
      alignItems: 'center',
      height: "56px",
      border: "1px solid #D7D7D7",
      background: ColorsConstant.white,
      marginBottom: 20,
      borderRadius: "4px",
      position: 'relative',
      '@media (max-width: 767px)': {
        height: 50
      }
    },
    inputLabel: {
      color: "#B2B2B2",
      fontSize: "14px",
      fontWeight: 300,
      position: 'absolute',
      backgroundColor: ColorsConstant.white,
      top: -11,
      left: 12,
      padding: '0 5px',
      '@media (max-width: 767px)': {
        fontSize: 12,
        top: -9
      }
    },
    inputField: {
      color: "#6C6C6C",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: 400,
      padding: '0 17px',
      '@media (max-width: 767px)': {
        fontSize: 12
      }
    },
    "@media (min-width: 768px) and (max-width: 900px)": {
      header: {
        marginTop: "75px",
        marginBottom: "10px",
      },
    },
    "@media (min-width: 900px) and (max-width: 900px)": {
      header: {
        marginTop: "29px",
        marginBottom: "10px",
      },
    },
    "@media (min-width: 901px) and (max-width: 959px)": {
      header: {
        marginTop: "74px",
        marginBottom: "10px",
      },
    },
    rightContentContainer: {
      flex: 1,
      maxWidth: 500,
      paddingTop: 10,
      '@media (max-width: 767px)': {
        maxWidth: '100%',
        width: '100%'
      }
    },
    uploadIconDiv: {
        '& label': {
            cursor: 'pointer'
        }
    }
  };
  const classes = useStyles(styles)();

  return (
    <>
      <Box className={classes.header}>
        {!isMobile && <Box className={classes.title}>{t("my_profile")}</Box>}
        <Box
          className="notification-bar"
          style={{ marginLeft: "auto", marginTop: "-5px", paddingRight: "4px" }}
        >
          <NotificationBar />
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.profileSection}>
          <Box>
            <Box className={classes.userProfile}>
                <Box className={classes.uploadIconDiv}>
                <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="upload-image"
                />

                <label htmlFor="upload-image">
                    <CameraAltOutlinedIcon />
                </label>
                </Box>
                <CUserImageIcon
                    src={user.image !== "" ? `${user.image}` : defaultImage}
                />
            </Box>
            {errorMessage && (
              <Box className={classes.fileUploadMsg}>{errorMessage}</Box>
            )}
          </Box>
          <Box className={classes.rightContentContainer}>
            {user?.name && (
              <Box className={classes.inputBox}>
                <Box className={classes.inputLabel}>{t("name")}</Box>
                <Box className={classes.inputField}>{user?.name}</Box>
              </Box>
            )}
            {user?.email && (
              <Box className={classes.inputBox}>
                <Box className={classes.inputLabel}>{t("emailAddress")}</Box>
                <Box
                  className={classes.inputField}
                  style={{ textTransform: "lowercase" }}
                >
                  {user?.email}
                </Box>
              </Box>
            )}
            {user?.mobileNumber && (
              <Box className={classes.inputBox}>
                <Box className={classes.inputLabel}>{t("phone-number")}</Box>
                <Box className={classes.inputField}>{user?.mobileNumber}</Box>
              </Box>
            )}
            {user?.role && (
              <Box className={classes.inputBox}>
                <Box className={classes.inputLabel}>{t("Role")}</Box>
                <Box className={classes.inputField}>{user?.role}</Box>
              </Box>
            )}

            {user?.city && (
              <Box className={classes.inputBox}>
                <Box className={classes.inputLabel}>{t("city")}</Box>
                <Box className={classes.inputField}>{user?.city}</Box>
              </Box>
            )}
            
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UserProfile;
