import React, { useState } from 'react';
import './../QuizResult/quizResult.css';
import { useTranslations, useStyles } from "examsbook-react-sdk/custom-hooks";
import { getQuizStatus } from "examsbook-react-sdk/common";
import { useSelector } from "react-redux";
import { Modal, Box, Typography, ListItem, Button, List } from '@material-ui/core';
import ExclamationIcon from './../../../assets/images/alertIcon.png';
import OverlayButton from '../../../constants/OverlayButton';

/**
 * Confirmation button.
 * @param props
 * @returns {*}
 * @constructor
 */
function ConfirmButton(props) {
    const { mode, text, clickOnYes, clickOnNo } = props;
    const t = useTranslations();
    const quiz = useSelector((state) => state.quiz);
    const { answered, marked, notAnswered, notAnsweredVisited } = getQuizStatus(quiz.questions);
    const [ showPopup, setShowPopup ] = useState(false);
    const classes = useStyles(styles)();

    const popupBody = (
        <Box className={classes.paper}>
            <Box className={classes.backgroundYellow}></Box>
            <Box component="img" src={ExclamationIcon} alt=""/>
            <Box component="h2">{t('Are_you_sure_you_want_to_finish')}</Box>
            <Typography variant="span">{t('FINISH_MODAL_DESC')}</Typography>
            <Box className={classes.quizQuestionList}>
                <List>
                    <ListItem className={classes.attemptedBorder}>
                        <Box className={classes.alignDiv}><span className={classes.attempted}/>
                        <Box component="p">{t('attempted')}</Box></Box>
                        <Box component="h4" className={classes.attemptedCount}>{answered}</Box>
                    </ListItem>
                    <ListItem className={classes.notAttemptedBorder}>
                        <Box className={classes.alignDiv}><span className={classes.notAttempted}/>
                        <Box component="p">{t('not_attempted')}</Box></Box>
                        <Box component="h4" className={classes.notAttemptedCount} >{notAnswered}</Box>
                    </ListItem>
                    <ListItem className={classes.skippedBorder}>
                        <Box className={classes.alignDiv}><span className={classes.skipped}/>
                        <Box component="p">{t('SKIPPED')}</Box></Box>
                        <Box component="h4" className={classes.skippedCount}>{notAnsweredVisited}</Box>
                    </ListItem>
                    <ListItem>
                        <Box className={classes.alignDiv}><span className={classes.reviseLater}/>
                        <Box component="p">{t('MARKED')}</Box></Box>
                        <Box component="h4" className={classes.markedCount}>{marked}</Box>
                    </ListItem>
                </List>
            </Box>
            <Box className={classes.quizButtonGroups}>
                <Button onClick={() => onResume()} className={classes.cancelBtn}>{t('CANCEL')}</Button>
                <Button onClick={() => handleFinish()} className={classes.finishBtn}>{t('FINISH')}</Button>
            </Box>
        </Box>
    );

    function handleFinish() {
        onSubmitConfirmation();
        onResume();
    }
    function submitBtnClick() {
        setShowPopup(!showPopup);
    }

    function onResume() {
        setShowPopup(!showPopup);
        clickOnNo();
    }

    function onSubmitConfirmation() {
        setShowPopup(!showPopup);
        clickOnYes();
    }

    function handleClose() {
        setShowPopup(false);
    }

    return (
        <>
            {
                showPopup && <Modal
                    open={showPopup}
                    onClose={handleClose}
                    className={classes.finishModal}
                >
                    {popupBody}
                </Modal>
            }
            <Box className="confirm-button">
                <OverlayButton onClick={submitBtnClick} disabled={mode === 'preview'} title={text} classStyleHeading={showPopup ? classes.selectedBtn : ''} classStyleText={classes.confirmText}/>
            </Box>
        </>
    );

}

const styles = theme => ({
    // quiz screen css starts from here
    quizButtonGroups: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 30,
        gap: 20,
        '& button': {
            width: '160px',
            background: '#fff',
            color: '#000',
            fontSize: 17,
            fontWeight: '400',
            padding: '16px 0',
            outline: 'none',
            boxShadow: 'none',
            border: '1px solid #E7E7E7',
            letterSpacing: '1px',
            textTransform: 'capitalize',
            cursor: 'pointer',
            '@media (min-width: 280px) and (max-width: 320px)': {
                width: '100px',
                padding: '10px 0'
            },
        }
    },
    cancelBtn : {
        background: '#ffffff !important',
        color: '#000000 !important',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        },
    },
    confirmText: {
        textTransform: 'capitalize'
    },
    finishBtn: {
        background: '#FFC658 !important',
        color: '#fff !important',
        fontWeight: '700 !important',
        '&:hover': {
            backgroundColor: '#ffc658d1 !important'
        },
    },
    alignDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    quizInstructionScreen: {
        display: 'none',
        '@media (max-width: 767px)': {
            position: 'fixed',
            top: 55,
            left: 0,
            padding: '30px 15px 20px',
            right: 0,
            height: 'calc(100vh - 55px)',
            background: '#fff',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        }
    },
    quizTopicName: {
        '& h4': {
            margin: 0,
            color: '#000',
            fontSize: 20,
            fontWeight: 600,
            textAlign: 'center',
            whiteSpace: 'normal'
        },
        '& p': {
            color: '#000',
            margin: '20px 0 14px',
            fontSize: 15,
            fontWeight: 600,
            textAlign: 'center'
        },
        '& span': {
            fontSize: 36,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            color: '#000'
        }
    },
    quizQuestionList: {
        marginBottom: 16,
        width: '-webkit-fill-available',
        '& > ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            border: '1px solid #E1E1E1',
            '@media (min-width: 280px) and (max-width: 500px)': {
                display: 'block',
                minWidth: '100%'
            },
            '& > li': {
                display: 'flex !important',
                background: '#FFFFFF',
                alignItems: 'center',
                color: '#000 !important',
                minWidth: '50%',
                maxWidth: '50%',
                width: '50%',
                padding: '20px !important',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                '@media (min-width: 280px) and (max-width: 500px)': {
                    minWidth: '100%'
                },
                '& h4': {
                    width: '21%',
                    margin: 0,
                    fontSize: 18,
                    textAlign: 'end',
                    fontWeight: '400',
                    '@media (min-width: 280px) and (max-width: 425px)': {
                        fontSize: 14,
                    },
                },
                '& span': {
                    width: 12,
                    height: 12,
                    display: 'flex',
                    borderRadius: '50%',
                    marginRight: 8,
                },
                '& p': {
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: '#434343',
                    minWidth: 'fit-content',
                }
            }
        }
    },
    notAttemptedBorder: {
        borderBottom: '1px solid #E1E1E1'
    },
    notAttempted: {
        background: '#C70000'
    },
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        justifyContent: 'center',
        boxShadow: 'none',
        boxSizing: 'border-box',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
            width: 60,
            marginTop: '-25px'
        },
        '& > h4': {
            fontSize: 17,
            color: '#00BFA5',
            margin: 0,
            marginTop: 20,
            paddingBottom: 10,
        },
        '& > p': {
            fontSize: 13,
            color: '#00BFA5',
            margin: 0,
        },
        '& > h2': {
            textAlign: 'center',
            fontSize: 24,
            color: '#000',
            margin: 0,
            padding: '20px 0 20px',
        },
        '& > span': {
            color: '#000000',
            textAlign: 'center',
            fontSize: 18,
            marginBottom: 30,
            fontWeight: 400,
            lineHeight: '20.97px'
        },
        '@media (max-width: 550px)': {
            width: '95%',
        }
    },
    backgroundYellow: {
        background: '#FFC658 !important',
        width: 'inherit',
        height: 50,
    },
    attemptedBorder: {
        borderRight: '1px solid #E1E1E1',
        borderBottom: '1px solid #E1E1E1',
        '@media (min-width: 280px) and (max-width: 320px)': {
            borderRight: 0
        },
    },
    attempted: {
        background: '#27AE60',
    },
    finishModal: {
        zIndex: '9999 !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    reviseLater: {
        background: '#0B2F8A'
    },
    skipped: {
        background: '#FFC658'
    },
    skippedBorder: {
        borderRight: '1px solid #E1E1E1',
        '@media (min-width: 280px) and (max-width: 320px)': {
            borderRight: 0,
            borderBottom: '1px solid #E1E1E1',
        },
    },
});
export default ConfirmButton;
