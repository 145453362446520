import React, { useEffect, useState } from "react";
import "../CoursesList/coursesList.scss";
import TestSeriesLoader from "../common/loaders/TestSeriesLoader";
import { useUserCourse } from "examsbook-react-sdk/custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import { useSearchQuery } from "../../services/SearchQueryContext";
import NotFound from "../../pages/NotFound";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/close.svg";
import CoursesCard from "../CoursesCard/CoursesCard";
import { certificateListAction } from "examsbook-react-sdk/actions/certificateAddResponse";
import { useTranslations } from 'examsbook-react-sdk/custom-hooks';
import { Box, Button, Input, makeStyles } from "@material-ui/core";
import { getCourseList } from "examsbook-react-sdk/actions";

function stripHtml(html) {
    let tmp = document.createElement("p");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText;
}

function CourseList(props) {
    const [ selectedTab, setSelectedTab ] = useState(1);
    const [ contentData, setContentData ] = useState("");
    const dispatch = useDispatch();
    const t = useTranslations();
    const [ certificateListing, setCertificateListing ] = useState([]);
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const classes = useStyles(isFirefox);

    const user = useSelector((state) => state.user);
    const { searchQuery, setSearchQuery } = useSearchQuery();
    const { isLoading } = useUserCourse(user._id);
    const [ showComponent, setShowComponent ] = useState(false);

    const lowerSearchQuery = searchQuery.toLowerCase().trim();
    const filteredUserCourses =
        contentData &&
        contentData.filter((data, index) => {
            const title = data?.name;
            const description = data?.description;
            const descriptionText = stripHtml(description).toLowerCase();
            const lowerTitle = title.toLowerCase();
            return (
                lowerTitle.includes(lowerSearchQuery) ||
                descriptionText.includes(lowerSearchQuery)
            );
        });
    const coursesToDisplay =
        searchQuery && searchQuery.trim() !== ""
            ? filteredUserCourses
            : contentData;

    useEffect(() => {
        getCertificateListingData();
    }, []);

    const getCourseListApiData = async (userId) => {
        try {
            dispatch(getCourseList(userId)).then((res) => {
                let sortedData = res?.data?.sort(
                    (a, b) => new Date(b.created.on) - new Date(a.created.on)
                );
                setContentData(sortedData);
            });
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        if (user._id) {
            getCourseListApiData(user?._id);
        }
    }, [ user._id ]);

    function clearField(searchQuery) {
        if (searchQuery.length) {
            setSearchQuery("");
        }
    }

    useEffect(() => {
        return () => {
            localStorage.removeItem('selectedTab');
        };
    }, []);

    useEffect(() => {
        setSearchQuery('');
        return setSearchQuery('');
    }, []);

    const getCertificateListingData = async () => {
        try {
            dispatch(certificateListAction()).then((res) => {
                setCertificateListing(res.data);
            });
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 800);
        return () => clearTimeout(timer);
    }, []);

    function renderList() {
        return (
            <React.Fragment>
                {selectedTab === 1 && (
                    <>
                        <Box className="main-div">
                            <Box className="course-search-box">
                                <Input
                                    className="inner-search-box"
                                    style={{ border: "none" }}
                                    type="text"
                                    placeholder={t('Search')}
                                    value={searchQuery}
                                    onChange={(event) => setSearchQuery(event.target.value)}
                                />
                                <Box className="course-search-img-box">
                                    {searchQuery.length ? (
                                        <Button
                                            onClick={() => clearField(searchQuery)}
                                            style={{ border: "none" }}
                                        >
                                            <CrossIcon className="cross-icon" alt="Cross Icon"/>
                                        </Button>
                                    ) : (
                                        <SearchIcon className="search-icon" alt="Search Icon"/>
                                    )}
                                </Box>
                            </Box>

                            <Box className="card-list-div">
                                {
                                    coursesToDisplay && coursesToDisplay.length > 0 ? (
                                        <Box className="card-view-new test-series-new ">
                                            {coursesToDisplay.map((data, index) => {
                                                return <CoursesCard key={index} testData={data}
                                                                    dueDateinDays={data?.dueDateinDays}
                                                                    certificateListing={certificateListing}/>;
                                            })}
                                        </Box>
                                    ) : showComponent && (
                                        <>{<Box className={classes.centerAlign}><NotFound/></Box>}</>
                                    )
                                }
                            </Box>
                        </Box>
                    </>
                )}
            </React.Fragment>
        );
    }

    function renderLoader() {
        //we will show three cards to fill this space.
        return (
            <Box className="card-view test-series">
                <TestSeriesLoader/>
                <TestSeriesLoader/>
                <TestSeriesLoader/>
                <TestSeriesLoader/>
            </Box>
        );
    }

    return (
        <React.Fragment>
            {!isLoading ? renderList() : coursesToDisplay?.length > 0 && renderLoader()}
        </React.Fragment>
    );
}

const useStyles = makeStyles(() => ({
    centerAlign: isFirefox => ({
        textAlign: isFirefox ? '-moz-center' : '-webkit-center',
    }),
}));
export default CourseList;
