import React from 'react';
import QuestionOptionListItem from "./QuestionOptionListItem";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import {Box} from '@material-ui/core';
/**
 * Options list.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionOptionList(props) {
    const { type, options, viewMode, activeQuestionIndex, onRadioChange, givenAnswer } = props;
    const classes = useStyles(styles)();
    return (
        <Box className={classes.options}>
            <Box component="ul">
                {
                    options && options.map((item, index) => {
                        let optionClass = '';
                        if (viewMode) {
                            if (item.correct) {
                                optionClass = 'correctoption';
                            } else if (givenAnswer && givenAnswer.indexOf(item._id) > -1) {
                                optionClass = 'incorrectoption';
                            }
                        }
                        let checked = givenAnswer && givenAnswer.indexOf(item._id) > -1;
                        return <QuestionOptionListItem item={item} index={index}
                                                       checked={checked}
                                                       type={type}
                                                       key={index}
                                                       optionClass={optionClass}
                                                       onRadioChange={onRadioChange}
                                                       activeQuestionIndex={activeQuestionIndex}/>;
                    })
                }
            </Box>
        </Box>
    );
}

const styles = theme => ({
    options: {
        float: "left",
        width: "calc(100% - 0px)",
        WebkitBoxSizing: "border-box",
        boxSizing: "border-box",
        padding: "0 20px 20px 0px",
        '@media (min-width: 280px) and (max-width: 767px)': {
            marginLeft: "-28px"
        },
        '& ul': {
            margin: "0",
            padding: "0",
            width: "100%",
            float: "left",
            '& li': {
                listStyle: "none",
                width: "calc(100% - 0px)",
                float: "left",
                paddingRight: "0",
                textAlign: "left",
                boxSizing: 'border-box',
                cursor: "pointer",
                marginBottom: "10px",
                transition: "0.3s all ease-in-out",
                paddingLeft: "40px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                '&:after': {
                    position: "absolute",
                    left: "5px",
                    top: "13px",
                    content: "'A.'",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#111",
                    width: 25,
                    height: 25,
                    display: 'none',
                    alignItems: 'center',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    background: '#e1e2e1'
                },
                '&:nth-child(1):after': { content: "'A'" },
                '&:nth-child(2):after': { content: "'B'" },
                '&:nth-child(3):after': { content: "'C'" },
                '&:nth-child(4):after': { content: "'D'" },
                '&:nth-child(5):after': { content: "'E'" },
                '&:nth-child(6):after': { content: "'F'" },
                '&:nth-child(7):after': { content: "'G'" },
                '&:nth-child(8):after': { content: "'H'" },
                '&:nth-child(9):after': { content: "'I'" },
                '&:nth-child(10):after': { content: "'J'" },
            }
        }
    }
});
export default QuestionOptionList;
