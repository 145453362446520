import React, { useEffect } from "react";
import { ReactComponent as QuizIcon } from "../../assets/images/quiz.svg";
import { ReactComponent as PDFIcon } from "../../assets/images/test.svg";
import { ReactComponent as VideoIcon } from "../../assets/images/video.svg";
import greenTick from "../../assets/images/greenTick.png";
import { ReactComponent as TextIcon } from "../../assets/images/textIcon.svg"
import "./SelfLearning.scss";
import { Box } from '@material-ui/core';
import { selfLearningVideoUrl } from "examsbook-react-sdk/actions/selfLearningVideoUrl";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { ColorsConstant } from "../../constants/colorConstant";
import { setDataId, setSectionId } from "examsbook-react-sdk/actions/getSelfLearningAction";
export default function SelfLearningSubmenu(props) {
  let subMenuName = props;
  let iconName = props?.data?.refModel;
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  dispatch(setDataId(props?.selectedSubmenu));
  const sectionIdFromRedux = useSelector(state => state.selfLearningReducers.sectionId);
  useEffect(() => {
    if (props?.sectionId && (props?.sectionId!==null || props?.sectionId!==undefined)) {
      dispatch(setSectionId(props?.sectionId));
    }
  }, [props?.sectionId, dispatch, sectionIdFromRedux]);

  const { sectionIndex, index, handleUpdateCourseProgress, setCurrentSectionIndex, setCurrentDataIndex, openPdfViewer  } = props;

  const handleGreenTick = (data) => {
    if (data?.isRead && data.percent === 100) {
      return <Box component="img" src={greenTick} alt="Completed" />;
    }
     else {
      return (
        <Box>
        </Box>
      );
    }
  };

  const handleOnClick = (id) => {
    setCurrentDataIndex(index);
    setCurrentSectionIndex(sectionIndex);
    props.setSelectedSubmenu(id);
    dispatch(setSectionId(props?.sectionId));
    if (props?.data?.refModel === "RecordedVideo") {
      dispatch(selfLearningVideoUrl(subMenuName));
      props.setSectionChanged(false);
    } else if (props?.data?.refModel === "TextNotes") {
      dispatch(selfLearningVideoUrl(subMenuName));
      let data = {
        sectionId: props?.sectionId,
        dataId: props?.data?._id,
        percent: 100,
        courseId: props?.courseId ? props?.courseId : props?.section?.data?.courseId?._id,
        isRead: true
      }
      handleUpdateCourseProgress(data)
    } else if (props?.data?.refModel === "PdfNotes") {
      let data = {
        sectionId: props?.sectionId,
        dataId: props?.data?._id,
        percent: 100,
        courseId: props?.courseId ? props?.courseId : props?.section?.data?.courseId?._id,
        isRead: true
      }
      handleUpdateCourseProgress(data)
      props.onPdfOpen(!props?.pdfOpen);
      openPdfViewer(props?.data?.contentData?.featured);
    }
    if (props.onSubmenuClick) {
      props.onSubmenuClick(props);
    }
  };

  return (
    <>
      <div
        id={props?.index}
        className="outerBox2 submenu isHover"
        style={{
          backgroundColor: props?.selectedSubmenu === props?.data?._id ? ColorsConstant.backgroundHighlight : 'transparent'
        }}
      onClick={() => handleOnClick(props?.data?._id)}
      >
        <div className="innerBox">
          <div className="submenu-image">
            {iconName === "Quiz" && <QuizIcon />}
            {iconName === "RecordedVideo" && <VideoIcon />}
            {iconName === "PdfNotes" && <PDFIcon />}
            {iconName === "TextNotes" && <TextIcon />}
            {iconName === "Questions" && <QuizIcon />}
          </div>
          <p className={classes.genustext}>{props?.data?.name}</p>
        <Box>{handleGreenTick(props?.data)}</Box>
        </div>
      </div>
    </>
  );
}

const styles = {
  genustext: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    gap: '10px',
    width: '-webkit-fill-available',
    fontSize: '14px',
    color: '#000000',
    opacity: '0.7',
    fontWeight: 400,
    "@media (max-width: 767px)": {
      width: "auto",
    },
  },
};
