import React, { useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from "react-redux";
import InstructionBlock from './InstructionBlock/InstructionBlock';
import { getQuestionLocation } from "examsbook-react-sdk/selectors";
import { setActiveQuestionIndex, saveQuizResponse } from "examsbook-react-sdk/actions";
import QuestionPlayground from "./QuestionPlayground";
import HeaderSidebar from "./HeaderSidebar";
import { useTranslations, useWindowSize } from "examsbook-react-sdk/custom-hooks";
import { getQuizStatus } from "examsbook-react-sdk/common";
import ExclamationIcon from './../../assets/images/alertIcon.png';
import PauseQuizIcon from "../../assets/images/pauseQuizIcon.png";
import ResumeArrow from '../../assets/images/resumeArrow.png';
import closeArrow from "../../assets/images/closeQuiz.png";
import { useHistory } from "react-router-dom";
import { getQuizResultResponse } from "examsbook-react-sdk/actions/getQuizResultResponse";
import { certificateAddResponse } from 'examsbook-react-sdk/actions/certificateAddResponse';
import { courseAddedNotification } from 'examsbook-react-sdk/actions/getNotifications';
import CommonButton from '../../constants/CommonButton';
import { Box, Button, List, ListItem, Typography, makeStyles, Modal } from '@material-ui/core';

/**
 * QuizPlayground component.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizPlayground(props) {
    const t = useTranslations();
    const { quizMode, viewMode, isLoading, quiz, totalQuestions, slug } = props;
    const questions = useSelector(state => state.quiz.questions);
    const activeQuestionIndex = useSelector(state => state.activeQuestionIndex);
    const questionLocationHead = useSelector(state => getQuestionLocation(state));
    const dispatch = useDispatch();
    const [ toggleClass, setToggleClass ] = useState('');
    const [ showInstructionBox, setInstructionBox ] = useState(false);
    const [ quizIsStarted, setQuizStarted ] = useState(true);
    const [ isDrawerClosed, setDrawerToggle ] = useState(false);
    const [ changeSectionActive, setActiveSection ] = useState(questionLocationHead.sectionNo || 0);
    const currentRef = useRef(0);
    const windowSize = useWindowSize();
    const [ isFullscreen, setFullScreen ] = useState(false);
    const [ pauseQuiz, setPauseQuiz ] = useState(false);
    const [ ModelOpen, setModelOpen ] = useState(false);
    const [reset, setReset] = useState(false); // reset the timer when question changes
    const classes = useStyles();
    const { answered, marked, notAnswered, notAnsweredVisited } = getQuizStatus(quiz.questions);

    const total = useSelector((state) => state.quiz.totalQuestions);
    const [hideNext, setHideNext] = useState(false);
    const user = useSelector((state) => state.user);
    let userId = user?._id;
    const history = useHistory();
    const [isModalVisible, setModalVisible] = useState(false);
    let courseId = localStorage.getItem('courseId')
    localStorage.setItem('quizMode',quizMode)
    localStorage.setItem('slug',slug)
    useEffect(() => {
        if (windowSize.width <= 767) {
            setDrawerToggle(true);
        } else {
            setDrawerToggle(false);
        }
    }, [ windowSize ]);

    useEffect(() => {
        const quizCompleted = localStorage.getItem('quizCompleted');
        if (quizCompleted === 'true') {
            setPauseQuiz(true);
            openModal();
        }
    }, []);

    useEffect(() => {
        if(activeQuestionIndex===(total-1)) {
            setHideNext(true);
        } else {
            setHideNext(false);
        }
    },[activeQuestionIndex, total])

    function handleFullScreen() {
        setFullScreen(!isFullscreen);
    }

    /**
     * Select question on question palette click
     * @param questionIndex
     */
    function selectPaletteQuestion(questionIndex) {
        dispatch(setActiveQuestionIndex(questionIndex));
        setToggleClass('');
    }

    /**
     * toggle instructions.
     */
    function toggleInstructionBox() {
        setInstructionBox(!showInstructionBox);
    }

    // start quiz.
    function startQuiz() {
        setQuizStarted(true);
        setInstructionBox(false);
    }

    //global function to set time taken. it get stored in db while submission.
    function setTimeTakenFn(timeTaken) {
        currentRef.current = timeTaken;
    }

    // submit the quiz when tab changes.
    useEffect(() => {
        function handleVisibilityChange() {
            if (document.visibilityState === 'hidden') {
                setPauseQuiz(true);
                submitQuiz();
                openModal();
                }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);   

    function toggleClick() {
        setToggleClass(toggleClass === '' ? 'toggled' : '');
    }

    function toggleDrawerHandler() {
        setDrawerToggle(!isDrawerClosed);
    }

    function submitQuiz() {
        saveQuizResponseFn(true);
    }

    function handleQuizPauseChange(value) {
        setPauseQuiz(value);
        setModelOpen(value);
    }

    const openModal = () => {
        setModalVisible(true);
        setPauseQuiz(true);
    };
    
    const QuizSuccessful = ({ showModal, closeModal }) => {
        localStorage.setItem('quizCompleted', 'true');
        return (
          <Box className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
            <Box className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
              <Box className={classes.heading}>
                  <Typography variant="h2" className={classes.headingText}>{t('Your quiz is submitted successfully!')}</Typography>
                  <Box className={classes.okAlign}>
                    <CommonButton onClick={() => { 
                        history.replace('/my-courses', { params: window.location.pathname});
                        history.push('/reports',  {courseId:slug, pageType: "afterTestResult", params: window.location.pathname});localStorage.removeItem('quizCompleted');}} classStyleHeading={classes.okBtn} title={t('Ok')}/>
                  </Box>
                </Box>
            </Box>
          </Box>
        );
    };

    /**
     *
     * @param type
     * Desc: handle next and prev navigation from footer
     */
    async function handleClickOnQuestionNavigator(type) {
        if (questions && questions.length) {
            if ((questionLocationHead.questionNo + 1) === questions[questionLocationHead.sectionNo].questions.length) {
                setActiveSection(questionLocationHead.sectionNo + 1);
            }
        }
        if (type === 'next') {
            if (totalQuestions - 1 > activeQuestionIndex) {
                dispatch(setActiveQuestionIndex(activeQuestionIndex + 1));
            } else if (!viewMode) {
                //it is last question, promt user to submit quiz

                confirmAlert({
                    closeOnClickOutside: true,
                    overlayClassName: "overlay-custom-class-name",
                    customUI: ({ onClose }) => {
                        function handleFinishFn(){
                            submitQuiz();
                            openModal();
                            onClose();
                        } 
                        return (
                        <Box className={classes.paper}>
                            <Box className={classes.backgroundYellow}></Box>
                            <Box component="img" src={ExclamationIcon} alt=""/>
                            <Box component="h2">{t('Are_you_sure_you_want_to_finish')}</Box>
                            <Typography variant="span">{t('FINISH_MODAL_DESC')}</Typography>
                            <Box className={classes.quizQuestionList}>
                                <List>
                                    <ListItem className={classes.attemptedBorder}>
                                        <Box className={classes.alignDiv}><span className={classes.attempted}/>
                                        <Box component="p">{t('attempted')}</Box></Box>
                                        <Box component="h4" className={classes.attemptedCount}>{answered}</Box>
                                    </ListItem>
                                    <ListItem className={classes.notAttemptedBorder}>
                                        <Box className={classes.alignDiv}><span className={classes.notAttempted}/>
                                        <Box component="p">{t('not_attempted')}</Box></Box>
                                        <Box component="h4" className={classes.notAttemptedCount} >{notAnswered}</Box>
                                    </ListItem>
                                    <ListItem className={classes.skippedBorder}>
                                        <Box className={classes.alignDiv}><span className={classes.skipped}/>
                                        <Box component="p">{t('SKIPPED')}</Box></Box>
                                        <Box component="h4" className={classes.skippedCount}>{notAnsweredVisited}</Box>
                                    </ListItem>
                                    <ListItem>
                                        <Box className={classes.alignDiv}><span className={classes.reviseLater}/>
                                        <Box component="p">{t('MARKED')}</Box></Box>
                                        <Box component="h4" className={classes.markedCount}>{marked}</Box>
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className={classes.quizButtonGroups}>
                                <Button onClick={() => onClose()} className={classes.cancelBtn}>{t('CANCEL')}</Button>
                                <Button onClick={() => handleFinishFn()} className={classes.finishBtn}>{t('FINISH')}</Button>
                            </Box>
                        </Box>);

                    }
                });
            }
        } else if (type === 'prev') {
            if (activeQuestionIndex > 0) {
                dispatch(setActiveQuestionIndex(activeQuestionIndex - 1));
            }
        }
    }
    const saveQuizResponseFn = async (completed) => {
                if (!viewMode && quizMode !== 'preview') {
            const res = await dispatch(saveQuizResponse({
                slug: slug,
                response: questions,
                timeTaken: currentRef.current,
                completed: completed, 
                courseId: courseId
            }));
                if (res && quizMode !== 'practice-quiz') {
                const quizResult = await dispatch(getQuizResultResponse(res.slug ? res.slug : slug, courseId))
                const passingPercent = quizResult?.passingPercent;

        let quizValue = quizResult?.result[quizResult?.result?.length-1];
        let finalScore = 0;
        let totalQuestions = 0
        
        const finalData = quizValue.map((item,index)=>{
            finalScore = finalScore + item?.score;
            totalQuestions = totalQuestions + item?.total
        })
        Promise.all(finalData)
        let percentage = (finalScore / totalQuestions) * 100;
        finalScore = 0;
        totalQuestions = 0

                  if (parseInt(percentage) >= (passingPercent)) {
                  const certificatePayload = {
                    courseId: courseId,
                    isCompleted: true,
                    score: parseInt(quizResult?.result[quizResult?.result?.length-1][0]?.score),
                    mark: parseInt(quizResult?.result[quizResult?.result?.length-1][0]?.total),
                  };
                    await dispatch(
                    certificateAddResponse(certificatePayload)
                  );
                    // certificate unlocked notification api
                    let data = {
                        tag: "Course Qualified",
                        courseId: [courseId],
                    }
                    dispatch(courseAddedNotification(userId, data));
                }
            }
        }
        return;
    }

    return (
        <React.Fragment>{quizIsStarted || viewMode ?
            <Box className={"App " + toggleClass}>
                <HeaderSidebar viewMode={viewMode}
                               selectPaletteQuestion={selectPaletteQuestion}
                               drawerToggle={isDrawerClosed}
                               changeSectionActive={changeSectionActive}
                               quizMode={quizMode}
                               quizIsStarted={quizIsStarted}
                               setTimeTaken={setTimeTakenFn}
                               toggleDrawerHandler={toggleDrawerHandler}
                               submitQuiz={submitQuiz}
                               showInstructionBox={showInstructionBox}
                               isLoading={isLoading}
                               isFullscreen={isFullscreen}
                               handleFullScreen={handleFullScreen}
                               pauseQuiz={pauseQuiz}
                               handleClick={handleClickOnQuestionNavigator}
                               reset={reset}
                               setReset={setReset}
                               hideNext={hideNext}
                               slug={slug}
                               handleQuizPauseChange={handleQuizPauseChange}/>
                <QuestionPlayground mode={quizMode}
                                    viewMode={viewMode}
                                    slug={slug}
                                    drawerToggle={isDrawerClosed}
                                    toggleClick={toggleClick}
                                    handleClick={handleClickOnQuestionNavigator}
                                    reset={reset}
                                    setReset={setReset}
                                    isLoading={isLoading}
                                    setPauseQuiz = {setPauseQuiz}
                                    pauseQuiz = {pauseQuiz}
                                    handleQuizPauseChange={handleQuizPauseChange}/>
            </Box> : (showInstructionBox && questions.length > 0 ?
                <InstructionBlock
                    quiz={quiz}
                    quizIsStarted={quizIsStarted}
                    toggleInstructionBox={toggleInstructionBox}
                    startQuiz={startQuiz}/>
                : '')

        }
            <Modal
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={ModelOpen}
            >
                <Box className={classes.quizPauseModel}>
                <Box className={classes.crossIconDiv}>
                    <button onClick={() => handleQuizPauseChange(false)} className={classes.closeArrowIcon}>
                        <img alt='' src={closeArrow} />
                    </button>
                </Box>
                    <img src={PauseQuizIcon} alt='pause quiz icon'/>
                    <h2>{t("QUIZ_PAUSED")}</h2>
                    <p>{t("QUIZ_PAUSED_MSG")}</p>
                    <button onClick={() => handleQuizPauseChange(false)} title={t("RESUME")}
                            className={classes.resumeBtn}>
                        <img alt='' src={ResumeArrow}/>
                        <span>{t("RESUME")}</span>
                    </button>

                </Box>
            </Modal>
            {
                isModalVisible && 
                    <QuizSuccessful/>
            }
        </React.Fragment>
    );

}

const useStyles = makeStyles(theme => ({
    quizPauseModel: {
        width: '420px',
        margin: 'auto',
        marginTop: '24vh',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 6, 3),
        color: '#34495E',
        textAlign: 'center',
        borderTop: '16px solid #0C3450',
        '& h2': {
            fontWeight: '700',
        },
        '& p': {
            fontWeight: '500',
            fontSize: 16,
            lineHeight: '24px',
            color: '#84818A'
          },
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    closeArrowIcon: {
        display: 'inline', 
        position: 'absolute', 
        background: '#fff', 
        border: 0, 
        right: '16px', 
        cursor: 'pointer'
    },
    crossIconDiv: {
        position: 'relative',
        left: '35px'
    },
    alignDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    resumeBtn: {
        background: '#fff',
        cursor: 'pointer',
        padding: '15px 25px 15px 25px',
        border: '1px solid #E7E7E7',
        boxShadow: '4px 4px 8px 0px #C6C6C640;',
        marginTop: 10,
        color: '#0C3450',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '22.7px',
        '& > img': {
            position: 'absolute'
        },
        '& > span': {
            marginLeft: '30px'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        },
    },
    backgroundYellow: {
        background: '#FFC658 !important',
        width: 'inherit',
        height: 50,
    },
    attemptedBorder: {
        borderRight: '1px solid #E1E1E1',
        borderBottom: '1px solid #E1E1E1',
        '@media (min-width: 280px) and (max-width: 500px)': {
            borderRight: 0
        },
    },
    notAttemptedBorder: {
        borderBottom: '1px solid #E1E1E1'
    },
    skippedBorder: {
        borderRight: '1px solid #E1E1E1',
        '@media (min-width: 280px) and (max-width: 500px)': {
            borderRight: 0,
            borderBottom: '1px solid #E1E1E1',
        },
    },
    paper: {
        position: 'absolute',
        width: 500,
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #000',
        boxShadow: 'none',
        boxSizing: 'border-box',
        padding: '0px 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
            width: 60,
            marginTop: '-25px'
        },
        '& > h4': {
            fontSize: 17,
            color: '#00BFA5',
            margin: 0,
            marginTop: 20,
            paddingBottom: 10,
        },
        '& > p': {
            fontSize: 13,
            color: '#00BFA5',
            margin: 0,
        },
        '& > h2': {
            textAlign: 'center',
            fontSize: 24,
            color: '#000',
            margin: 0,
            padding: '20px 0 20px',
        },
        '& > span': {
            color: '#000000',
            textAlign: 'center',
            fontSize: 18,
            marginBottom: 30,
            fontWeight: 400,
            lineHeight: '20.97px'
        },
        '@media (max-width: 550px)': {
            width: '95%',
        }
    },
    finishModal: {
        zIndex: '9999 !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    attempted: {
        background: '#27AE60',
    },
    reviseLater: {
        background: '#0B2F8A'
    },
    skipped: {
        background: '#FFC658'
    },
    notAttempted: {
        background: '#C70000'
    },
    quizQuestionList: {
        marginBottom: 16,
        width: '-webkit-fill-available',
        '& > ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            border: '1px solid #E1E1E1',
            '@media (min-width: 280px) and (max-width: 500px)': {
                display: 'block',
                minWidth: '100%'
            },
            '& > li': {
                display: 'flex !important',
                background: '#FFFFFF',
                alignItems: 'center',
                color: '#000 !important',
                minWidth: '50%',
                maxWidth: '50%',
                width: '50%',
                padding: '20px !important',
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                '@media (min-width: 280px) and (max-width: 500px)': {
                    minWidth: '100%'
                },
                '& h4': {
                    width: '21%',
                    margin: 0,
                    fontSize: 18,
                    textAlign: 'end',
                    fontWeight: '400',
                    '@media (min-width: 280px) and (max-width: 425px)': {
                        fontSize: 14,
                    },
                },
                '& span': {
                    width: 12,
                    height: 12,
                    display: 'flex',
                    borderRadius: '50%',
                    marginRight: 8,
                },
                '& p': {
                    margin: 0,
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: '#434343',
                    minWidth: 'fit-content'
                }
            }
        }
    },
    quizButtonGroups: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        gap: 20,
        '& button': {
            width: '160px',
            // background: '#fff',
            color: '#000',
            fontSize: 17,
            fontWeight: '400',
            padding: '16px 0',
            outline: 'none',
            boxShadow: 'none',
            border: '1px solid #E7E7E7',
            letterSpacing: '1px',
            textTransform: 'capitalize',
            cursor: 'pointer',
            '@media (min-width: 280px) and (max-width: 500px)': {
                width: '100px',
                padding: '10px 0'
            },
        }
    },
    finishBtn: {
        background: '#FFC658 !important',
        color: '#fff !important',
        fontWeight: '700 !important',
        '&:hover': {
            backgroundColor: '#ffc658d1 !important'
        },
    },
    cancelBtn : {
        background: '#ffffff !important',
        color: '#000000 !important',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        },
    },
    modalBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100% !important',
        background: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '999999'
    },   
    modalContent: {
        width: '460px',
        margin: 'auto',
        marginTop: '40vh',
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    heading: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#fff',
        padding:'20px 40px',
        borderRadius: '6px',
    },
    headingText: {
        fontWeight:'normal',
        color: 'black',
        textAlign: 'center',
        fontSize: '1.4em',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        lineHeight: '1.5',
    },
    okBtn: {
        fontWeight: '500',
        padding:'10px 30px',
        borderRadius: '4px',
        fontSize:'15px',
        margin:'15px',
        backgroundColor:'#0C3450',
        color:'white',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    okAlign: {
        display:'flex',
        flexDirection:'row'
    }
}));

export default QuizPlayground;
