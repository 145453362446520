import React from 'react';
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { getQuizStatus, TimeUtils } from "examsbook-react-sdk/common";
import classNames from 'classnames';
import {Box} from '@material-ui/core';
/**
 * Quiz result mini on sidebar.
 * @param props
 * @returns {*}
 * @constructor
 */
const QuizCompactResult = props => {
    const { viewMode, questions, timeTaken, duration } = props;
    const { totalMarks, obtained } = getQuizStatus(questions);
    const classes = useStyles(styles)();
    const t = useTranslations();
    let timeSpent;
    if (viewMode) {
        timeSpent = TimeUtils.changeTimeFormat(timeTaken);
    }
    return (
        <Box>
            {viewMode ?
                <Box className={classNames(classes.totalMarksSection)}>
                    <Box className={classNames(classes.totalMarks)}>
                        <Box component="span" className={classNames(classes.totalMarksText)}>{t('total_marks')}</Box>
                        <Box component="span" className={classNames(classes.totalMarksContainer)}>
                            {parseFloat(obtained).toFixed(2) || 0} / {totalMarks || 0}
                            </Box>
                    </Box>
                    <Box className={classNames(classes.totalMarks)}>
                        <span className={classNames(classes.totalMarksText)}>{t('total_time')}</span>
                        <span
                            className={classNames(classes.totalMarksContainer)}>
                            {timeSpent ? timeSpent : '00:00'} / {duration ? duration : '00:00'} Mins
                        </span>
                    </Box>
                </Box> : ''}
        </Box>
    );
};

const styles = theme => ({
    totalMarksSection: {
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 15,
        marginRight: 15,
        borderWidth: 1,
        borderBottomColor: '#eee',
        marginTop: '15px',
        '@media (max-width: 767px)': {
            display: 'none'
        }
    },
    totalMarks: {
        marginBottom: 12,
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    totalMarksText: {
        width: 120,
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    totalMarksContainer: {
        padding: '2px 5px',
        display: 'flex',
        color: '#5F4BAB',
        backgroundColor: '#5f4bab21',
        borderRadius: 2,
        fontSize: 13,
        border: '1px solid #5F4BAB'
    }
});

export default QuizCompactResult;
