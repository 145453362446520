import React from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useDyanmicSeoSettings, useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { Box, Grid, Typography } from '@material-ui/core';
import { useTheme } from '../../constants/themeProvider';
import { useSelector } from "react-redux";

export default function CTabs(props) {
    const user = useSelector((state) => state.user);
    const { dynamicSeoSettings } = useDyanmicSeoSettings(user);
    const certificateValue = dynamicSeoSettings?.find(subValue => subValue.key === "Activate Certificate")?.value;
    const { mySectionData } = props;
    const t = useTranslations();
    const theme = useTheme();
    const drawerToggle = localStorage.getItem('openDrawerStatus')

    const styles = {
    root: {
        backgroundColor: 'white',
        padding: '0px !important',
        display: 'flex',
        flexDirection: 'column',
        "@media (max-width: 959px)": {
            display: 'block'
        },
    },
    title: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'center',
        color: '#000000',
        textTransform: 'capitalize',
        textAlign: 'left',
    },
    titleResize: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        display: 'flex',
        justifyContent: 'center',
        color: '#000000',
        textTransform: 'capitalize',
        textAlign: 'left',
        "@media (max-width: 959px)": {
            width: '100%',
            textAlign: 'center',
            fontSize: '10px'
        },
        "@media (min-width: 960px) and (max-width: 1200px)": {
            fontSize: '10px !important'
        },
    },
    iconTab: {
        alignItems: 'center',
    },
    iconTabSelected: {
        borderBottom: `1px solid ${theme?.secondaryColor}`,
        alignItems: 'center',
        opacity: '1'
    },
    activeLogo: {
        transition: '.2s all linear',
    },
    activeTitle: {
        color: theme?.secondaryColor,
        fontSize: '16px',
        fontWeight: 600,
        '@media (max-width: 767px)': {
           fontSize: 10
        },
        "@media (min-width: 960px) and (max-width: 1200px)": {
            fontSize: '10px'
        }
    },
    };

    const classes = useStyles(styles)();

    return ( 
        <Tabs 
        value={props.setActiveTab}
        aria-label="course tabs"
        indicatorColor="primary"
        >
            <Grid container lg={8}>
            {props?.nav?.map((item, index) => {
                const label = item.label;
                const isActive = props.setActiveTab === index;
                const activeIcon = item.activeIcon;
                const shouldShowTab = (index < 3 || (index === 3 && certificateValue==='true'));
                const isLiveClassTab = index === 1 && mySectionData?.data?.liveClass?.length === 0;
                const isEvaluationTestTab = index === 2 && mySectionData?.data?.evaluationTest?.length === 0;
                if (isLiveClassTab || isEvaluationTestTab) return null;
                return (
                shouldShowTab && (
                <Grid item xs={3} md={3} lg={3} onClick={() => props.handleTabChange(index)} key={index}>
                    <Tab
                    style={{ width: '100%'}}
                        label={
                            <Box className="feature-buttons">
                                <Box
                                className={`${drawerToggle && window.innerWidth < 1200 ? classes.logoResize : classes.logo} ${isActive ? classes.activeLogo : ''} `}
                                >
                                    <Box className={`${drawerToggle && window.innerWidth < 1200 ? classes.titleResize : classes.title} ${isActive ? classes.activeTitle: ''}`}>
                                        <Typography variant="p">{t(label)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        key={index}
                        className={isActive ? classes.iconTabSelected : classes.iconTab}
                    />
                </Grid>
                ));
            })}
            </Grid>
        </Tabs>
    );    
}
