import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import status from "../constants/actionStatus";
import { getUserSupportTicketsAction } from "../actions/getSupportAction";

/**
 * get list of all tickets submitted by this user.
 * @returns {*}
 */
export default function useUserSupportTickets(userId) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserSupportTicketsAction(userId));
    }, [ userId ]);
    let ticketsState = useSelector((state) => state.userSupportTickets);
    let isLoading = ticketsState.status === status.PENDING;
    let tickets = ticketsState.tickets.data;
    return {
        isLoading,
        tickets,
    };
}
