import React from 'react';
// import './questionBox.css';
import MathJax from 'react-mathjax4';
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import useUserLanguage from "examsbook-react-sdk/custom-hooks/useUserLanguage";
import { editor } from 'examsbook-react-sdk/common';
import {Box, Typography} from '@material-ui/core';
/**
 * Direction of a question.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionDirection(props) {
    const { direction, localesDirection } = props;
    const classes = useStyles(styles)();
    const quizDirectionClass = direction && (direction.type).toUpperCase() === 'LONG_TEXT' ? classes.quizDirectionBlock : '';
    const { language } = useUserLanguage();

    // convert math type question readable format
    editor(direction.direction ? direction.direction : localesDirection);
    
    return (
        <Box className={classes.quizDirection + ' ' + classes.box + ' ' + quizDirectionClass}>
            {direction && direction.direction ?
                <Typography variant='h4'>
                    {(language === 'hn') && <MathJax.Context input="tex">
                        <MathJax.Html html={localesDirection ? localesDirection : direction.direction}/>
                    </MathJax.Context>}
                </Typography> : null}
            <Typography variant='h4'>
                {(language === 'en') && <MathJax.Context input="tex">
                    <MathJax.Html html={direction.direction ? direction.direction : localesDirection}/>
                </MathJax.Context>}
            </Typography>
        </Box>
    );
}

const styles = theme => ({
    quizDirection: {
        width: '50%',
        '@media (max-width:767px)': {
            display: 'none'
        },
    },
    quizComprehension: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'calc(100vh - 190px)',
    },
    quizDirectionBlock: {
        maxHeight: 'calc(100vh - 190px)',
        overflow: 'auto'
    },
    box: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 190px)',
        float: 'left',
        width: 'calc(100% - 20px)',
        boxShadow: '0 0 2px #ccc',
        margin: 10,
        backgroundColor: '#fff',
        position: 'relative',
        textAlign: 'left',
        padding: 10,
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d3d5d3'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#eeecec'
        },
        '& h4': {
            fontWeight: 'normal'
        }
    }
});

export default QuestionDirection;
