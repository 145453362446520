import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';

export const CHeader = ({ children, classes }) => {
    return (
        <Box className={classes.root}>
            {children}
        </Box>
    );
};

export const CNavLink = ({ children, to, href, isExact }) => {
    if (href) {
        return (
            <a href={href}>
                {children}
            </a>
        )
    }
    return (
        <NavLink exact={isExact || false} to={to}>
            {children}
        </NavLink>
    );
};
