import React from 'react';
import './noData.scss';
import { Box, Typography } from '@material-ui/core';
/**
 * NoData component.
 * @param props
 * @returns {*}
 * @constructor
 */
function NoData(props) {
    return (
        <Box className="no-data">
        <Typography variant='h3'>No data found!</Typography>
        </Box>
    );
}

export default NoData;
