import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useTranslations,
  useStyles,
  useDefaultSeoSettings,
  useDyanmicSeoSettings
} from "examsbook-react-sdk/custom-hooks";
import rectangleQuizImage from "../../../assets/images/rectangleQuiz.png"
import { useHistory } from "react-router-dom";
import { getCourseSectionApiData } from "examsbook-react-sdk/actions/getSelfLearningAction";
import { useDispatch, useSelector } from "react-redux";
import OverlayButton from "../../../constants/OverlayButton";
import { useTheme } from "../../../constants/themeProvider";
import moment from 'moment';
import CommonButton from "../../../constants/CommonButton";
import { getQuizDataForQuizIdAction } from "examsbook-react-sdk/actions/getQuiz";
import {Radio, FormControlLabel, Button, Typography, Grid, Checkbox, Box} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ColorsConstant } from "../../../constants/colorConstant";

/**
 * Instructions block.
 * @param props
 * @returns {*}
 * @constructor
 */
function InstructionBlock(props) {
  let dispatch = useDispatch();
  const [endDate, setEndDate] = useState("");
  const [mySectionData, setMySectionData] = useState([]);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(styles)();
  const t = useTranslations();
  let course_id = localStorage.getItem("courseId");
  const [testDisabled, setTestDisabled] = useState(false);
  const [quizAttemptsCount, setQuizAttemptsCount] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [quizTimeDifference, setQuizTimeDifference] = useState('');
  const [courseStartDate, setCourseStartDate] = useState('')
  let learningData = useSelector((state) => state.selfLearningReducers);
  const { dataId, sectionId } = learningData;
  const { startQuiz, quiz, quizAttemptForResult, unlockEvaluationTest, certificateUnlocked, quizEndDate, startButtonClicked, selectedQuizCard, courseName, handleUpdateCourseProgress } = props;
  const currentDateTime = new Date();
  const convertedQuizEndDate = moment(quizEndDate, "DD-MM-YYYY HH:mm:ss");
  const convertedQuizCurrentDateTime = moment(currentDateTime, "DD-MM-YYYY HH:mm:ss");
  const difference = moment.duration(convertedQuizEndDate.diff(convertedQuizCurrentDateTime));
  const formattedDifference = `${difference.days()} days, ${difference.hours()} hours, ${difference.minutes()} minutes, ${difference.seconds()} seconds`;
  const user = useSelector((state) => state.user); 
  const { seoSettings } = useDefaultSeoSettings(user);
  const { dynamicSeoSettings } = useDyanmicSeoSettings(user);
  const fetchData = async () => {
    dispatch(getCourseSectionApiData(course_id)).then((response) => {
      try {
        const courseStartingDate = moment(response?.data?.courseId?.startDate).format('DD-MM-YYYY, HH:mm:ss');
        setCourseStartDate(courseStartingDate)
        setMySectionData(response?.data?.evaluationTest[0]);
      } catch (error) {
        console.log('Error -', error.message);
      }
    });
  };

    useEffect(() => {
      const defaultQuizAttemptsCount = seoSettings?.['Quiz Attempts'][0]?.defaultValue;
        const dynamicQuizAttemptsCount = dynamicSeoSettings?.find(item =>  item.key === 'Quiz Attempts')?.value;
        setQuizAttemptsCount(dynamicQuizAttemptsCount ? dynamicQuizAttemptsCount : defaultQuizAttemptsCount);
    },[seoSettings, dynamicSeoSettings]);


  const isTestDisabled = unlockEvaluationTest === false || mySectionData?.quizAttempts >= quizAttemptsCount || certificateUnlocked === true;

  useEffect(() => {
    if (course_id !== undefined) {
      fetchData();
    }
  }, [quiz]);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
   setModalVisible(false);
  };

  const Modal = ({ showModal, closeModal }) => {
      return (
          <Box className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
              <Box className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                  <Box className={classes.heading}>
                      <h2 className={classes.headingText}>{t('Your quiz is expired!')}</h2>
                      <Box className={classes.okAlign}>
                          <CommonButton onClick={() => history.push('/courses')} classStyleHeading={classes.okBtn} title={t('Ok')}/>
                      </Box>
                  </Box>
              </Box>
          </Box>
      );
  };

  useEffect(() => {

    if(startButtonClicked) {
      setQuizTimeDifference(formattedDifference);
      history.push('/quiz/' + selectedQuizCard?.data?.evaluationTest?.[0]?.testId?.mode + '/' + selectedQuizCard?.data?.evaluationTest?.[0]?.testId?.slug);
    }
  },[startButtonClicked])
  const courseStartingDate = courseStartDate && moment(courseStartDate, 'DD-MM-YYYY, HH:mm:ss') > moment()
  useEffect(() => {
    if (quiz?._id) {
      dispatch(getQuizDataForQuizIdAction(quiz._id)).then((response) => {
          const endDate = response.data.data.endDate;
          const formattedEndDate = new Date(endDate)
            .toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
            .replace(/\//g, "-");
          setEndDate(formattedEndDate);

          if (isTestDisabled) {
            setTestDisabled(true);
          } else {
            setTestDisabled(false);
          }
        })

        .catch((error) => {
          console.error("Error fetching quiz data:", error);
        });
    }
  }, [quiz?._id, courseStartDate]);


  const showResult = ()=>{
    history.push('/reports',  {courseId:course_id, slug:quiz?.slug, pageType: "Instruction"  })
  }

  // for practice quiz

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);

  let questions = [];
  let currentQuestion = null;
  let mappedOptions = [];

// Assign values based on mode
if (props?.quiz?.mode !== 'mock-test') {
  questions = props?.quiz?.questions || [];
  currentQuestion = questions[currentQuestionIndex];
  if (currentQuestion) {
    mappedOptions = currentQuestion?.options.map((option) => ({
      value: option?._id,
      label: option?.text.replace(/<[^>]+>/g, ""),
      isCorrect: option?.correct === true,
    }));
  }
}


  // Handle option selection
  const handleOptionChange = (event) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [currentQuestionIndex]: event.target.value,
    });
  };

  // Handle option selection for multiple-choice questions
  const handleOptionChangeForMultiple = (event, optionValue) => {
  const isChecked = event.target.checked;

  setSelectedAnswers((prev) => {
    const currentAnswers = prev[currentQuestionIndex] || [];
    if (isChecked) {
      // Add the selected option to the list
      return {
        ...prev,
        [currentQuestionIndex]: [...currentAnswers, optionValue],
      };
    } else {
      // Remove the unselected option from the list
      return {
        ...prev,
        [currentQuestionIndex]: currentAnswers.filter((val) => val !== optionValue),
      };
    }});
  };

  // Move to the next question
  const handleNext = () => {
    if (currentQuestionIndex < questions?.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Move to the previous question
  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Check if all questions have been attempted
  const allQuestionsAnswered = questions.every((_, index) => {
    const selectedAnswer = selectedAnswers[index];
    return Array.isArray(selectedAnswer) ? selectedAnswer.length > 0 : !!selectedAnswer;
  });

  // Submit the quiz
  const handleSubmit = () => {
  let correct = 0;
  let incorrect = 0;
  questions.forEach((question, index) => {
    const selectedAnswer = selectedAnswers[index] || [];
    const correctOptions = question?.options?.filter((option) => option.correct).map((option) => option._id);

    if (Array.isArray(selectedAnswer)) {
      // For multiple-choice questions
      const isAllCorrect =
        selectedAnswer.length === correctOptions.length &&
        selectedAnswer.every((val) => correctOptions.includes(val));

      if (isAllCorrect) {
        correct += 1;
      } else {
        incorrect += 1;
      }
    } else {
      // For single-choice questions
      if (correctOptions.includes(selectedAnswer)) {
        correct += 1;
      } else {
        incorrect += 1;
      }
    }
  });

  setCorrectCount(correct);
  setIncorrectCount(incorrect);
  setSubmitted(true);
  setCurrentQuestionIndex(0);
    if(correct === questions?.length && incorrect === 0){
      let data = {
        sectionId: sectionId,
        dataId: dataId,
        percent: 100,
        courseId: course_id,
        isRead: true
      }
      handleUpdateCourseProgress(data)
    }
  };

  // Render logic for options
  const isMultipleChoice = currentQuestion?.options.filter((option) => option?.correct).length > 1;

  const renderOptions = () => {
    return mappedOptions.map((option) => {
        const isSelected = selectedAnswers[currentQuestionIndex]?.includes(option?.value) || selectedAnswers[currentQuestionIndex] === option?.value;
        const isAnswerCorrect = isSelected && submitted && option?.isCorrect;
        const isAnswerWrong = isSelected && submitted && !option?.isCorrect;

        return (
          <Box
            key={option?.value}
            className={`${classes.optionContainer} ${
              isAnswerWrong
                ? classes.incorrectOption
                : isAnswerCorrect
                ? classes.correctOption
                : ""
            }`}
          >
            <Box className={classes.iconContainer}>
              {isAnswerCorrect && (
                <CheckCircleIcon className={classes.checkIcon} />
              )}
              {isAnswerWrong && (
                <CancelIcon className={classes.crossIcon} />
              )}
            </Box>
              {isMultipleChoice ? 
                <Box className={classes.checkboxLabelContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={(e) => handleOptionChangeForMultiple(e, option?.value)}
                        disabled={submitted}
                      />
                    }
                    label={option?.label}
                  />
                </Box>
              : 
                <Box className={classes.radioLabelContainer}>
                  <FormControlLabel
                    value={option?.value}
                    control={
                      <Radio
                        checked={isSelected}
                        onChange={handleOptionChange}
                        disabled={submitted}
                      />
                    }
                    label={option?.label}
                  />
                </Box>}
            </Box>
        );
    });
  };

  // retake the quiz
  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswers({});
    setCorrectCount(0);
    setIncorrectCount(0);
    setSubmitted(false);
  };

  // Check if the option is correct
  const isOptionCorrect = (option) => {
    return option?.correct === true;
  };

  return (
    <>
      {props?.quiz?.mode !== 'mock-test' ? (
      <>
        <Box className={classes.practiceBox}>
          {submitted && (
            <Box className={classes.headerBox}>
              <Box className={classes.scoreInfo}>
                <Box className={classes.scoreItem}>
                  <Box className={`${classes.scoreBox} ${classes.correctBox}`} />
                    <Typography className={classes.correctInfo}>
                      Correct <span className={classes.correctInfoCount}>{correctCount}</span>
                    </Typography>
                  </Box>
                  <Box className={classes.scoreItem}>
                <Box className={`${classes.scoreBox} ${classes.incorrectBox}`} />
                  <Typography className={classes.correctInfo}>
                    Incorrect <span className={classes.correctInfoCount}>{incorrectCount}</span>
                  </Typography>
                </Box>
              </Box>
                <Button className={classes.retakeButton} onClick={handleRetake}>
                  <ReplayIcon />
                  Retake
                </Button>
            </Box>
          )}
          <Box className={classes.practiceDataBox}>
            <Box className={classes.questionNumber}>
              {currentQuestionIndex + 1}/{questions?.length}
            </Box>
            <Box
              className={classes.questionText}
              dangerouslySetInnerHTML={{ __html: currentQuestion?.title }}
            ></Box>
            <Box className={classes.options}>{renderOptions()}</Box>
            <Box className={classes.buttonContainer}>
            {currentQuestionIndex > 0 && (
              <Button
                className={classes.previousButton}
                variant="outlined"
                onClick={handlePrev}
              >
                <Typography>{"<"}</Typography> Prev
              </Button>
            )}
          {currentQuestionIndex < questions?.length - 1 ? (
            <Button
              className={classes.nextButton}
              variant="contained"
              onClick={handleNext}
            >
              Next <Typography>{">"}</Typography>
            </Button>
            ) : (
                <>
                  {submitted ? (
                    <Button
                    className={classes.disabledButton}
                    variant="contained"
                    disabled // This disables the button functionality
                  >
                    Next <Typography>{">"}</Typography>
                  </Button>
                  ) : (
                  <Button
                    className={classes.nextButton}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!allQuestionsAnswered}
                  >
                    Submit
                  </Button>
                  )}
                </>
            )}
          </Box>
        </Box>
      </Box>
    </>
    ) : (
    <Box className={classes.wrapper} style={{ backgroundImage: `url(${rectangleQuizImage})`,  backgroundSize: 'cover', padding: `${quiz?.mode === "mock-test" ? '0px' : '35px 16px 40px'}`, border: `${quiz?.mode === "mock-test" ? 'none' : '1px solid #E3E3E3'}`, boxShadow: `${quiz?.mode === "mock-test" ? 'none' : '2px 4px 8px 0px rgba(198, 198, 198, 0.25)'}`}}>
        <Box style={{fontSize: '34px', fontWeight:'500', paddingBottom: '8px'}}>{courseName}</Box>
        <Box style={{fontSize: '24px', fontWeight:'400', paddingBottom: '32px'}}>{quiz?.mode === "practice-quiz" ? 'Practice Quiz' : 'Evaluation Test'}</Box>
      {quiz?.mode === "mock-test" ?
      <>
      {/* <Box className={classes.bottomBlock}> */}

            <Grid container spacing={2}>
              <Grid item xs={3}>
              <Box className={classes.quizBlock}>
                <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>{props?.selectedQuizCard?.data?.evaluationTest[0]?.quizAttempts}/{quizAttemptsCount}</Box>
                <Box className={classes.countTitle}>{t("Attempt")}</Box>
              </Box>
              </Grid>
              <Grid item xs={3}>
              <Box className={classes.quizBlock}>
                <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz?.total}</Box>
                <Box className={classes.countTitle}>{t("Question")}</Box>
              </Box>
              </Grid>
              <Grid item xs={3}>
              <Box className={classes.marksBlock}>
                <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>
                  {quiz?.duration}
                  {":00"} {t("MIN")}
                </Box>
                <Box className={classes.countTitle}>{t("Duration")}</Box>
              </Box>
              </Grid>
              <Grid item xs={3}>
              <Box className={classes.expiryBlock}>
                <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz?.mark}</Box>
                <Box className={classes.countTitle}>{t("total_marks")}</Box>
              </Box>
              </Grid>
            </Grid>
          {/* </Box> */}
      </> :
      <Box className={classes.bottomBlock}>
        <Box className={classes.questionDuration} style={{display: 'flex'}}>
          <Box className={classes.quizBlock}>
            <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>{quiz.totalQuestions}</Box>
            <Box className={classes.countTitle}>{t("Question")}</Box>
          </Box>
          <Box className={classes.timeBlock} style={{borderTop: '0px'}}>
            <Box className={classes.dataCount} style={{color: theme?.secondaryColor}}>
              {quiz.duration}
              {":00"} {t("MIN")}
            </Box>
            <Box className={classes.countTitle}>{t("Duration")}</Box>
          </Box>
        </Box>
        </Box>
      }
      <Box className={classes.instructionText}>{t("General Instructions")}</Box>
      <Box className={classes.upperBlock}>
        <Box>
          <ul className={classes.blockInstruction}>
            <li>
              {t("YOU_WILL_GET_ONLY")} {t("THREE_ATTEMPTS")}{" "}
              {t("PLEASE_ENSURE_THAT_YOU_HAVE_RELIABLE_INTERNET")}
            </li>
            <li>{t("EVALUTE_ALL_OPTIONS_CAREFULLY")}</li>
            <li>
              {t("THIS_TEST_HAS")} {t("NEGATIVE_MARKING")}{" "}
              {t("YOU_WILL_SEE_NEGATIVE_POSITIVE_NEGATIVE_MARK")}
              {"."}
            </li>
            <li style={{ color: '#d71a20', fontWeight: '400' }}>
              {t("If you change the tab, return to a previous page or minimize the browser during the test, it will be automatically submitted")}
            </li>
            <li style={{ color: '#d71a20', fontWeight: '400' }}>
              {t("Once the certificate is unlocked, you cannot attempt the evaluation test again")}
            </li>
          </ul>
        </Box>
      </Box>
      {quiz?.mode === "mock-test" ? (
        <>
          <Box className={classes.buttonBlock}>
            <OverlayButton onClick={startQuiz} disabled={testDisabled} title={t("Start_Test")} classStyleHeading={!testDisabled ? classes.startBtn : classes.startBtn + " " + classes.buttonDisabled}/>
            {quizAttemptForResult != null && quizAttemptForResult > 0 && (
              <OverlayButton onClick={showResult} title={t("view_result")} classStyleHeading={classes.startBtn} />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.buttonBlock}>
              <OverlayButton onClick={startQuiz} title={t("Start_Test")} classStyleHeading={classes.startBtn}/>
          </Box>
        </>
      )}
      {
        isModalVisible && <Modal
          open={isModalVisible}
          onClose={closeModal}
        >
          {Modal}
        </Modal>
      }
    </Box>
    )}
    </>
  );
}

const styles = (theme) => ({
  testName: {
    color: '#0C3450',
    fontSize: '24px',
    fontWeight: 500,
  },
  practiceBox: {
    padding: "35px 16px 40px 40px",
    display: "block",
    height: "100%",
    "@media (min-width:280px) and (max-width: 767px)": {
      padding: "15px 0",
      height: "100%",
    },
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#0C34500A",
    borderRadius: "10px 10px 0 0",
    padding: "12px 12px 12px 40px",
    '@media (max-width: 767px)': {
      padding: "12px",
    }
  },
  scoreInfo: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  scoreItem: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  scoreBox: {
    width: "12px",
    height: "12px",
    borderRadius: "2px",
  },
  correctBox: {
    backgroundColor: "#2E7D32",
  },
  incorrectBox: {
    backgroundColor: "#C62828",
  },
  correctInfo: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#000000B2',
  },
  correctInfoCount: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#000000DE',
  },
  retakeButton: {
    color: "#0C3450",
    fontSize: '14px',
    fontWeight: 600,
    padding: "5px 10px",
    borderRadius: "5px",
    textTransform : 'capitalize',
    "& .MuiSvgIcon-root": {
      width: '16px',
      height: '20px',
      marginRight: '8px',
    }
  },
  practiceDataBox: {
    backgroundColor: '#fff',
    padding: "35px 20px 40px 0px",
    boxShadow: `1.95px 1.95px 2.6px 0px ${ColorsConstant.boxShadow}`,
    display: "block",
    height: "100%",
    "@media (min-width:280px) and (max-width: 767px)": {
      padding: "15px 16px 0px 16px",
      height: "100%",
    },
  },
  questionNumber: {
    fontSize: "14px",
    fontWeight: 400,
    color: '#000',
    marginBottom: "16px",
    paddingLeft: '40px',
    '@media (max-width: 767px)': {
      paddingLeft: 0
    }
  },
  questionText: {
    fontSize: "24px",
    fontWeight: 500,
    color: '#000',
    marginBottom: "24px",
    paddingLeft: '40px',
    '@media (max-width: 767px)': {
      paddingLeft: 0,
      fontSize: 16,
    },
    "& p":{
      "& img":{
        width: '400px',
      },
    }
  },
  disabledButton: {
    textTransform : 'capitalize',
    "& .MuiButton-label": {
      gap: '8px',
    },
  },
  checkboxLabelContainer: {
    paddingLeft: '16px',
    '& .MuiCheckbox-colorSecondary': {
      '&.Mui-checked': {
      color: ColorsConstant.defaultBlue,
      }
    },

  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    borderRadius: "4px",
    marginBottom: "8px",
    justifyContent: "flex-start",
    paddingLeft: '10px',
    "& .MuiFormControlLabel-label ":{
          color: 'rgba(0, 0, 0, 0.50);',
          fontSize: "16px",
          fontWeight: 400,
        },
        '@media (max-width: 767px)': {
          padding: 0,
          paddingLeft: 30
        }
  },
  incorrectOption: {
    backgroundColor: "#FEEBEE",
    "& .MuiFormControlLabel-label ":{
          color: 'rgba(0, 0, 0, 0.55);',
          fontSize: "16px",
          fontWeight: 400,
        },
  },
  correctOption: {
    backgroundColor: "#F1F8E9",
    "& .MuiFormControlLabel-label ":{
          color: 'rgba(0, 0, 0, 0.55);',
          fontSize: "16px",
          fontWeight: 400,
        },
  },
  crossIcon: {
    marginRight: "8px",
    color: "#C62828",
  },
  checkIcon: {
    marginRight: "8px",
    color: "#2E7D32",
  },
  iconContainer: {
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8px",
    paddingLeft: '12px',
    '@media (max-width: 767px)': {
      paddingRight: 0,
      paddingLeft: 0,
      position: 'absolute',
      left: 38,
    }
  },
  radioLabelContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    paddingLeft: '16px',
    '& label > span': {
      '&.Mui-checked': {
        '& svg': {
          color: '#0C3450'
        }
      },
      '&:hover': {
        backgroundColor: 'rgba(245, 0, 87, 0.03) !important'
      }
    },
    '@media (max-width: 767px)': {
      paddingLeft: 0,
    }
  },
  buttonContainer: {
    marginTop: '72px',
    display: "flex",
    justifyContent: 'flex-end',
    gap: '10px',
  },
  nextButton: {
    backgroundColor: "#0C3450",
    color: "#fff",
    textTransform : 'capitalize',
    '&:hover': {
      backgroundColor: "#144160",
    },
    "& .MuiButton-label": {
      gap: '8px',
    },
  },
  previousButton: {
    borderColor: "#0C3450",
    color: "#000000",
    textTransform : 'capitalize',
    '&:hover': {
      borderColor: "#0C3450",
      color: "#0C3450",
    },
    "& .MuiButton-label": {
      gap: '8px',
    },
  },
  wrapper: {
    display: "block",
    height: 'calc(100svh - 100px)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    "@media (min-width:280px) and (max-width: 767px)": {
      padding: "15px 16px 0px 16px",
      height: "100%",
    },
  },
  questionDuration: {
    display: "flex",
    "@media (max-width: 767px)": {
      display: "block",
    },
    "@media (max-width: 960px)": {
      borderTop: "1px solid #E3E3E3"
    },
  },
  marksExpiry: {
    display: "flex",
    "@media (min-width:280px) and (max-width: 767px)": {
      display: "block",
    },
    "@media (max-width: 960px)": {
      borderTop: "1px solid #E3E3E3"
    },
  },
  upperBlock: {
    display: "flex",
    "@media (max-width: 960px)": {
      borderBottom: "none"
    },
  },
  instructionText: {
    color: "#0C3450",
    fontSize: '18px',
    fontWeight: 400,
    paddingTop: '55px',
    paddingBottom: '12px',
    "@media (max-width: 767px)": {
      fontSize: '1.2rem'
    },
  },
  blockInstruction: {
    color: "rgba(0, 0, 0, 0.50)",
    fontSize: '14px',
    fontWeight: 400,
    padding: "0px 20px",
    "& li": {
      paddingBottom: "15px",
    },
    "@media (max-width: 767px)": {
      fontSize: '1rem'
    },
  },
  showImage: {
    "@media (max-width:767px)": {
      display: "none",
    },
    "@media (min-width:960px) and (max-width: 1230px)": {
      display: "none",
    },
  },
  bottomBlock: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "18px 0px",
    borderBottom: "1px solid #E3E3E3",
  },
  quizBlock: {
    padding: "0px 20px",
    borderRight: "1px solid #E3E3E3",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  marksBlock: {
    padding: "0px 20px",
    borderRight: "1px solid #E3E3E3",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    "@media (max-width: 767px)": {
      borderTop: "1px solid #E3E3E3"
    }
  },
  expiryBlock: {
    padding: "0px 20px",
    // borderRight: "1px solid #E3E3E3",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width: 767px)": {
      borderRight: "0px",
    },
  },
  timeBlock: {
    padding: "0px 20px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width: 767px)": {
      borderTop: "1px solid #E3E3E3"
    }
  },
  dataCount: {
    textAlign: "center",
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: "25px",
    paddingBottom: "5px",
    "@media (max-width: 767px)": {
      fontSize: '1.2rem'
    },
  },
  countTitle: {
    color: "rgba(0, 0, 0, 0.50)",
    textAlign: "center",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: "22px",
  },
  startBtn: {
    "&:hover": {
      background: "#0c3450e0 !important",
    },
    width: "140px",
    height: "40px",
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: 600,
    cursor: "pointer",
    "@media (min-width:280px) and (max-width: 767px)": {
      width: 140,
      fontSize: 16,
    },
    "@media (min-width:960px) and (max-width: 1230px)": {
      width: 140,
      fontSize: 16,
    },
  },
  buttonBlock: {
    gap: '4px',
    textTransform: 'capitalize',
    display: "flex",
    justifyContent: "flex-start",
    "@media (min-width:280px) and (max-width: 767px)": {
      paddingBottom: 30,
    },
    "@media (min-width:768px) and (max-width: 1024px)": {
      paddingTop: 30,
    },
    "@media (min-width:1231px) and (max-width: 1650px)": {
      paddingTop: 10,
    },
  },
  selectLanguageContainer: {
    display: "flex",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    left: 0,
    margin: "auto",
    right: 0,
    width: "70%",
    padding: "10px 0",
    borderTop: "1px solid #ddd",
    justifyContent: "space-between",
    background: "#fff",
    "@media (min-width:768px) and (max-width: 1200px)": {
      width: "90%",
    },
    "& button": {
      position: "static",
      display: "flex",
      background: "#5F4BAB",
      color: "#fff",
      padding: "10px 20px",
      fontSize: 14,
      outline: "none",
      cursor: "pointer",
      boxShadow: "none",
      border: 0,
      borderRadius: 5,
      justifyContent: "center",
      letterSpacing: "2px",
      alignItems: "center",
      "& > svg": {
        marginLeft: 4,
        width: 20,
        height: 20,
        transform: "rotate(180deg)",
        color: "#fff",
      },
    },
  },
  instructionsMobileView: {
    display: "none",
    "@media (max-width: 767px)": {
      display: "flex",
      flexDirection: "column",
      "& h2": {
        textAlign: "center",
        width: "100%",
        fontSize: 16,
        color: "#000",
        margin: "25px 0",
      },
      "& ul": {
        listStyleType: "disc",
        padding: "0 25px",
        marginTop: 3,
        "& li": {
          fontSize: 13,
          color: "#000",
          marginBottom: 20,
        },
      },
      "& button": {
        position: "fixed",
        bottom: 20,
        width: "90%",
        margin: "auto",
        left: 0,
        right: 0,
        display: "flex",
        background: "#5F4BAB",
        textTransform: "uppercase",
        color: "#fff",
        padding: "16px 0",
        fontSize: 14,
        outline: "none",
        minHeight: "52px",
        boxShadow: "none",
        border: 0,
        borderRadius: 5,
        justifyContent: "center",
        letterSpacing: "2px",
        alignItems: "center",
        "& > svg": {
          marginLeft: 4,
          width: 20,
          height: 20,
          transform: "rotate(180deg)",
          color: "#fff",
        },
      },
    },
  },
  mobileBackHeader: {
    "& svg": {
      color: "#757575",
      width: 26,
      height: 26,
    },
  },
  instructionsBackBtn: {
    float: "left",
    width: "100%",
    lineHeight: "45px",
    textAlign: "left",
    "& a": {
      display: "inline-block",
      textDecoration: "none",
      color: "#039be5",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
      "& img": {
        width: 20,
        display: "inline-block",
        verticalAlign: "text-bottom",
        marginRight: 3,
      },
    },
  },
  questionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderBottom: "1px solid #ddd",
    padding: "30px 0",
    marginBottom: 30,
  },
  quesTag: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      width: 26,
      height: 26,
      marginRight: 10,
      color: "#5F4BAB",
      opacity: 0.5,
      "@media (min-width: 768px)": {
        opacity: 1,
      },
    },
    "& div": {
      "& span": {
        color: "#000",
        fontSize: 14,
        fontWeight: "bold",
        "@media (min-width: 768px)": {
          fontSize: 16,
        },
      },
      "& h4": {
        color: "rgba(0,0,0,0.4)",
        fontWeight: 400,
        fontSize: 14,
        margin: 0,
        paddingTop: 5,
        "@media (min-width: 768px)": {
          fontSize: 16,
        },
      },
    },
  },
  languageswitcher: {
    "& h4": {
      margin: 0,
      fontWeight: "bold",
      color: "rgba(0,0,0,0.4)",
      fontSize: 12,
      marginBottom: 15,
      "@media (min-width: 768px)": {
        fontSize: 14,
      },
      "& + div": {
        flexDirection: "row",
        "& label": {
          marginRight: 36,
          "& span": {
            fontSize: 14,
            "@media (min-width: 768px)": {
              fontSize: 14,
            },
            "& svg": {
              width: 22,
              height: 22,
              minWidth: 22,
            },
          },
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color: "#5F4BAB",
        },
      },
    },
  },
  languageswitcherWeb: {
    display: "flex",
    alignItems: "center",
    "& h4": {
      marginBottom: 0,
      marginRight: 20,
    },
  },
  buttonDisabled: {
    opacity: "0.4",
    cursor: "not-allowed",
  },
  modalBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9999'
  },
  modalContent: {
    width: '460px',
    margin: 'auto',
    marginTop: '40vh',
    '@media (max-width:439px)': {
      width: '280px'
    }
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    padding: '20px 40px',
    borderRadius: '6px'
  },
  headingText: {
    fontWeight: 'normal',
    color: 'black'
  },
  okBtn: {
    fontWeight: '500',
    padding: '10px 30px',
    fontSize: '15px',
    borderRadius: '4px',
    margin: '15px',
    backgroundColor: '#0C3450',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0c3450e0 !important',
    },
},
okAlign: {
    display:'flex',
    flexDirection:'row'
},
});
export default InstructionBlock;
