import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useTheme } from "../../constants/themeProvider";
import { useHistory } from "react-router-dom";
import DefaultCourseImage from "../../assets/images/course-image.jpg"
import { ColorsConstant } from "../../constants/colorConstant";


const CourseSideInfo = (props) => {
  const { courseCount, selectedCategory } = props;
  const t = useTranslations();
  const theme = useTheme();
  const classes = useStyles();
  const [showComponent, setShowComponent] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const validItems = courseCount?.sort((a, b) => new Date(b?.startDate) - new Date(a?.startDate))?.filter(item => {
    const currentDate = Date.now();
    const endDate = item?.courseId?.endDate || item?.endDate;
    const differenceInDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
    return ((selectedCategory === 'assigned' && item) ||
      (selectedCategory === 'expiring' && differenceInDays <= 7 && differenceInDays > 0) ||
      (selectedCategory === 'completed' && (item?.courseId?.progressPercent === 100 || currentDate > endDate)));
  });

  useEffect(() => {
    if (selectedCategory && validItems?.length) {
      setRandomKey(Math.random());
    }
  }, [selectedCategory]);

  return (
    <Grid className={`${classes.parentDiv} commonShadow`} container key={randomKey}>
      <Box className={classes.parentHeading}>
        <Box>
          <Typography variant="subtitle1" className={classes.headingText}>
            {selectedCategory === 'assigned' ? t('Courses Assigned') : selectedCategory === 'expiring' ? t('Courses Expiring') : t('Courses Completed')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.dataScroll}>
        {validItems && validItems.length ? validItems.map((item, index) => {
          const currentDate = Date.now();
          const endDate = item?.endDate;
          const courseImage = item?.featured?.largeImage?.jpg;
          const differenceInDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
          if ((selectedCategory === 'assigned' && item) ||
            (selectedCategory === 'expiring' && differenceInDays <= 7 && differenceInDays > 0) ||
            (selectedCategory === 'completed' && (item?.progressPercent === 100 || currentDate > endDate))) {
            return (
              <Box className={`${classes.dataBlock + ' ' + classes.dataScrollVisible}`}>
                <Box className={classes.leftHighlight}>
                  <Box component="img" 
                  src={ item?.featured 
                          ? item?.featured
                         : DefaultCourseImage
                            }
                  className={classes.imageAlign} />
                  {/* <Box component="img" src={courseImage?.split('/').pop() === '.jpg' || courseImage?.split('/').pop() === '.jpeg' || courseImage?.split('/').pop() === '.png' || courseImage?.split('/').pop() === '.webp' ? dynamicLogos : courseImage} className={classes.imageAlign} /> */}
                </Box>
                <Box className='content-child-div' style={{ width: '100%' }}>
                  <Box>
                    <Typography variant="subtitle1" className={classes.textFix}>{item?.courseId?.name}</Typography>
                    <Typography variant="subtitle2" style={{ color: theme?.secondaryColor }} className={classes.percentageBlock}>{Math.round(item?.progressPercent ? item?.progressPercent : 0)}% {t('Completed')}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          }
        }) : showComponent && <Box className={classes.noRecord}><Typography variant="subtitle2" className={classes.noData}>{t('No record found')}</Typography></Box>}
      </Box>
      <Typography className={`${classes.seeMore} ${ validItems && validItems.length <= 4 ? classes.disabled : '' }`} 
      onClick={
        validItems && validItems.length > 4
          ? () => history.push('/courses')
          : undefined
      }>{t('See More')}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    border: '1px solid rgba(12, 52, 80, 0.20)',
    background: '#FFF',
    display: 'block',
    height: '100%',
    borderRadius: '6px',
    position: 'relative',
    '@media (max-width:959px)': {
      maringTop: "20px",
    },
  },
  parentHeading: {
    width: '100%',
    alignSelf: 'center',
    padding: '0px 16px',
    borderBottom: `1px solid ${ColorsConstant.cardBorders}`
  },
  headingText: {
    margin: '16px 0px',
    fontWeight: '600',
    fontSize: 14,
  },
  dataBlock: {
    display: 'flex',
    width: '100%',
    padding: '16px',
    gap: '16px'
  },
  disabled: {
    color: `${ColorsConstant.gray} !important`,
    cursor: 'default !important',
    opacity: '0.3'
  },
  leftHighlight: {
    minWidth: '50px',
    minHeight: '50px',
    background: '#0c345017',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageAlign: {
    width: '40px',
    height: '40px',
    objectFit: 'contain'
  },
  noRecord: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '280px'
  },
  fontDark: {
    fontWeight: '500'
  },
  percentageBlock: {
    display: 'block',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
  noData: {
    display: 'block',
    margin: '16px',
    width: '100%',
    textAlign: 'center',
    color: '#00000080',
    fontSize: '1rem',
    fontWeight: '400'
  },
  dataScroll: {
    maxHeight: 340,
    overflow: 'auto'
  },
  dataScrollVisible: {
    animation: 'fadeIn 1s',
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1 },
  },
  textFix: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
    '@media (min-width:960px) and (max-width:1023px)': {
      maxWidth: '13vw',
    },
    '@media (min-width:1024px) and (max-width:1150px)': {
      maxWidth: '10ch !important'
    },
    '@media (min-width:1151px) and (max-width:1440px)': {
      maxWidth: '12vw',
    },
  },
  seeMore: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '14px',
    cursor: 'pointer',
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 0,
    color: ColorsConstant.defaultBlue,
    '@media (max-width: 767px)': {
      position: 'static'
    }
  }
}));
export default CourseSideInfo;