import React, { useState } from 'react';
import ConfirmButton from "../ConfirmButton/ConfirmButton";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import CommonButton from '../../../constants/CommonButton';
import { useTheme } from '../../../constants/themeProvider';
import { Box, Typography } from '@material-ui/core';

/**
 * SubmitButton button.
 * @param props
 * @returns {string}
 * @constructor
 */
function SubmitButton(props) {
    const { mode, viewMode, hideSubmitButton, slug, submitQuiz } = props;
    const t = useTranslations();
    const classes = useStyles(styles)();
    const history = useHistory();
    const [isModalVisible, setModalVisible] = useState(false);
    let courseId = localStorage.getItem('courseId')
    let testData = localStorage.getItem('testData')
    let quizCompleted = localStorage.getItem('quizCompleted');
    const theme = useTheme();

    function ConfirmAlertUI({ onClose, handleClose, submitQuiz, history, slug, t, theme }) {
        const classes = useStyles(styles)();
    
        return (
            <Box className={classes.popupBox}>
                <Typography variant="h2" className={classes.popupText}>{t("Are you sure to quit this quiz")}</Typography>
                <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box onClick={() => { handleClose(); submitQuiz(); history.push('/reports',  {slug:slug, pageType: "afterTestResult", params: window.location.pathname}); localStorage.removeItem('quizCompleted');}} className={classes.yesButton} style={{ backgroundColor: theme?.primaryColor }}>{t('Yes')}</Box>
                    <Box onClick={onClose} className={classes.noButton} style={{ backgroundColor: theme?.primaryColor }}>{t('No')}</Box>
                </Box>
            </Box>
        );
    }

    
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
        if (window.location.pathname.includes('/quiz') && !viewMode && !quizCompleted) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    const handleClose = () => {
                        onClose();
                        history.replace('/my-courses');
                        history.push('/course-material/content', { courseId: courseId, subjectId: '', topicId: '', subjectDetail: testData, subjectName: '', topicName: '' })
                    }
                    return <ConfirmAlertUI onClose={onClose} handleClose={handleClose} submitQuiz={submitQuiz} history={history} slug={slug} t={t} theme={theme} />;
                }
            });
            
        }
    };

    function handleClickOnYes() {
        openModal();
        props.submitQuiz();
        reactGaEvent("quiz", "Confirm Submit");
    }

    function handleClickOnNo() {
        reactGaEvent("quiz", "Not Confirm For Submit");
    }

    const openModal = () => {
        setModalVisible(true);
      };
    
      const closeModal = () => {
        setModalVisible(false);
      };

  const Modal = ({ showModal, closeModal }) => {
        localStorage.setItem('quizCompleted', 'true');
        return (
            <Box className={classes.modalBg + ` ${showModal ? 'show' : ''}`} onClick={closeModal} >
                <Box className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                    <Box className={classes.heading}>
                        <Typography variant="h2" className={classes.headingText}>{t('Your quiz is submitted successfully!')}</Typography>
                        <Box className={classes.okAlign}>
                            <CommonButton onClick={() => { 
                                history.replace('/my-courses', {params: window.location.pathname});
                                history.push('/reports',  {slug:slug, pageType: "afterTestResult", params: window.location.pathname}); localStorage.removeItem('quizCompleted');}} classStyleHeading={classes.okBtn} title={t('Ok')}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <React.Fragment>
            {viewMode ? 
                null
                 :
                <Box className={(hideSubmitButton ? classes.hideOnDesktop : '') + ' ' + classes.submit}>
                    <ConfirmButton mode={mode} text={t("Submit")}
                                   clickOnYes={handleClickOnYes}
                                   clickOnNo={handleClickOnNo}/>
                </Box>}
                    {
                        isModalVisible && <Modal
                            open={isModalVisible}
                            onClose={closeModal}
                        >
                            {Modal}
                        </Modal>
                    }
        </React.Fragment>
    );
}

const styles = theme => ({
    submit: {
        float: 'right',
        lineHeight: '33px',
        marginRight: 5,
        color: '#fff',
        '& button': {
            display: 'inline-block',
            padding: '14px 30px',
            borderRadius: 3,
            color: '#fff',
            fontSize: '18px',
            fontWeight: 500,
            backgroundColor: '#0C3450',
            lineHeight: '10px',
            height: 'auto',
            border: 0,
            cursor: 'pointer',
            textAlign: 'center',
            position: 'relative',
            boxShadow: '4px 4px #88888838',
            '&:hover': {
                background: '#0c3450e0 !important',
                color:'#fff',
                '@media (max-width: 767px)': {
                    background: '#0C3450',
                }
            }
        },
        '@media (max-width:767px)': {
            width: 'auto',
            '& button': {
                padding: '10px 15px',
            }
        }
    },
    hideOnDesktop: {
        '@media (max-width:767px)': {
            display: 'inline-block',
        }
    },
    modalBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },   
    modalContent: {
        width: '460px',
        margin: 'auto',
        marginTop: '40vh',
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    heading: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#fff',
        padding:'20px 40px',
        borderRadius: '6px'
    },
    headingText: {
        fontWeight:'normal',
        color: 'black',
        fontSize: '1.4em',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        lineHeight: '1.5',
    },
    okBtn: {
        fontWeight: '500',
        padding:'10px 30px',
        fontSize:'15px',
        borderRadius: '4px',
        margin:'15px',
        backgroundColor:'#0C3450',
        color:'white',
        cursor:'pointer',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    okAlign: {
        display:'flex',
        flexDirection:'row'
    },
    popupBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: '6px',
        padding: '20px 40px',
        width: '420px',
        '@media (max-width:439px)': {
            width: '280px'
        }
    },
    popupText: {
        fontWeight: 'normal',
        textAlign: 'center',
        fontSize: '1.4em',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        lineHeight: '1.5',
    },
    yesButton: {
        padding: '6px 30px',
        fontSize: '15px',
        margin: '15px',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#0c3450e0 !important',
        },
    },
    noButton: {
        padding: '6px 30px',
        fontSize: '15px',
        margin: '15px',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '4px'
    }
});
export default SubmitButton;
