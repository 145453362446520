import * as React from "react";
import { Box, Button } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";

/**
 * Single question solution modal.
 * @param props
 * @returns {*}
 * @constructor
 */

function QuestionSolutionModal(props) {
  const { question } = props;
  const t = useTranslations();
  const classes = useStyles(styles)();
  const base_url = window.location.origin;

  return (
    <Box>
      {question && question.id && question.id.solutionType === "PDF" && question.id.solutionPdfType === "LINK"  && question.id.solutionLink !== undefined && (
        <><Box>{t('VIEW_SOLUTION')}</Box>
        <Box className={classes.solutionText}>
        <Button className={classes.buttonText}>
          <a href={question?.id?.solutionLink} target="_blank" className={classes.urlLink}>
          <Box className={classes.iconButton}>
            <PictureAsPdfIcon />
            <p>{t("PDF")}</p>
          </Box>
          </a>
        </Button>
        </Box>
        </>
      )}

      {question && question.id && question.id.solutionType === "PDF" && question.id.solutionPdfType === "FILE" && (
        <><span>{t('VIEW_SOLUTION')}</span>
        <span className={classes.solutionText}>
        <Button className={classes.buttonText}>
          <a href={base_url + question?.id?.featured?.original?.pdf} target="_blank" className={classes.urlLink}>
            <Box className={classes.iconButton}>
              <PictureAsPdfIcon />
              <p>{t("PDF")}</p>
            </Box>
          </a>
        </Button>
        </span>
        </>
      )}
      <Box className={classes.viewSolutionModal}>
      </Box>
    </Box>
  );
}

const styles = (theme) => ({
  buttonText: {
    boxShadow: "#D7CCFF",
    width: "100px",
    height: "35px",
    background: "#5F4BAB",
    color: "#ffffff",
    border: "1px solid #5F4BAB",
    "&:hover": {
      background: "#5F4BAB",
    },
  },
  solutionText: {
    marginLeft: 15
  },
  urlLink: {
    color: '#fff',
    textDecoration: 'none'
  },
  iconButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding:50
  },
});
export default QuestionSolutionModal;
