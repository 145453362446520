import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import status from "../constants/actionStatus"
import { getDefaultSupportDataAction } from "../actions/getSupportAction";

/**
 * get list of seo settings
 * @returns {*}
 */
export default function useDefaultSeoSettings(user) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDefaultSupportDataAction());
    }, [user]);
    let seoSettingsState = useSelector((state) => state.defaultSeoSettings);
    let isLoading = seoSettingsState.status === status.PENDING;
    let seoSettings = seoSettingsState.defaultSeoSettings;
    return {
        isLoading,
        seoSettings,
    };
}
