import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { getUser } from "examsbook-react-sdk/actions/getUser";
import i18n from "examsbook-react-sdk/translations";
import Navigation from "../Navigation/Navigation";
import HeaderWrapper from "../common/Header/Header";
import { useScreenWidth } from "examsbook-react-sdk/custom-hooks";
import { useSearchQuery } from "../../services/SearchQueryContext";
import {
  getDefaultSupportDataAction,
  getSupportAction,
} from "examsbook-react-sdk/actions/getSupportAction";
import ThemeProvider from "../../constants/themeProvider";
import UnderMaintenance from "../common/UnderMaintenance/UnderMaintenance";
import { Box } from "@material-ui/core";
import WebNotFound from "../WebNotFound";
import { BottomNavigationTabs } from "../common/BottomNavigation";
import learnifyFavicon from "../../assets/images/learnifyFavicon.png";

function initializeReactGA() {
  ReactGA.initialize("UA-38243486-7");
}

initializeReactGA();

function MainContent({ children }) {
  const [openSidebar, setOpenSidebar] = useState(false);
  const user = useSelector((state) => state.user);
  const windowScreenWidth = useScreenWidth();
  const dispatch = useDispatch();
  const screenWidth = 900;
  const { newFlag } = useSearchQuery();
  const [defaultSupportData, setDefaultSupportData] = useState([]);
  const [dynamicSeoData, setDynamicSeoData] = useState([]);
  const [isValueUpdated, setValueUpdated] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [maintenance, setMaintenance] = useState("");

  useEffect(() => {
    if (window.location.pathname === "/course-material/content" || window.location.pathname === "/reports" || windowScreenWidth > 900 && windowScreenWidth < 1024) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(windowScreenWidth > screenWidth);
    }
  }, [windowScreenWidth, window.location.pathname]);

  const getSupportApiData = async () => {
    try {
      await dispatch(getSupportAction()).then((res) => {
        setValueUpdated(true);
        setNewData(res);
        setDynamicSeoData(res);
      });
    } catch (error) {
      console.log("Error -", error.message);
    }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getSupportApiData();
    getDefaultSeoSettingApiData();
  }, [isValueUpdated, user]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const dynamicMaintenanceValue = dynamicSeoData?.data
    ?.find((subValue) => subValue.key === "Maintenance Page")
    ?.value.toLowerCase()
    .trim();
  const defaultMaintenanceValue = defaultSupportData?.data?.[
    "Maintenance Page"
  ]?.find((subValue) => subValue.key === "Maintenance Page")?.defaultValue;

  const dynamicWebApplication = dynamicSeoData?.data
    ?.find((subValue) => subValue.key === "Web Application")
    ?.value.toLowerCase()
    .trim();

  useEffect(() => {
    if (
      typeof dynamicSeoData != "undefined" ||
      typeof defaultSupportData != "undefined"
    ) {
      if (
        typeof dynamicMaintenanceValue != "undefined" &&
        dynamicMaintenanceValue.length
      ) {
        setMaintenance(dynamicMaintenanceValue);
      }
    } else {
      setMaintenance(defaultMaintenanceValue);
    }
  }, [dynamicMaintenanceValue, defaultMaintenanceValue]);

  const setNewData = (supportData) => {
    let language = supportData?.data?.find(
      (subValue) => subValue.key === "DEFAULT_LANGUAGE"
    )?.value;

    if (language === "English" || language === "Hindi") {
      language === "English" ? (language = "en") : (language = "hn");
      const userLang = user.language || language;
      localStorage.setItem("default_language", language);
      localStorage.setItem("language", userLang);
      i18n.changeLanguage(userLang);
    }
    let dynamicLogo = "";
    // Setting dynamic favicon
    if (supportData || defaultSupportData) {
      const imageValue = supportData?.data?.find(
        (subValue) => subValue.key === "Site Logo"
      )?.value;

      
     
      if (imageValue) {
        dynamicLogo = imageValue;
      }

      
      // document.querySelector('link[rel="icon"]').href = dynamicLogo;
      // document.querySelector('link[rel="shortcut icon"]').href = dynamicLogo;
    }

    document.querySelector('link[rel="icon"]').href = dynamicLogo ? dynamicLogo : learnifyFavicon;
    document.querySelector('link[rel="shortcut icon"]').href = dynamicLogo ? dynamicLogo : learnifyFavicon;
  };

  //Fetch basic info.
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  //effect to check for user.
  useEffect(() => {
    if (!user || !user.loggedIn) {
      return;
    }
  }, [user]);

  // Prevent user to open Inspect window from key combinations.
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.code === "KeyI") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Prevent user to open Inspect window with right click.
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  function openDrawer(e) {
    setOpenSidebar(!openSidebar);
    e.preventDefault();
  }

  function closeDrawer() {
    setOpenSidebar(false);
  }

  return (
    <Box className="App">
      {dynamicWebApplication === "false" ? (
        <WebNotFound />
      ) : showComponent &&
        maintenance &&
        maintenance === "true" &&
        user?.role !== "admin" &&
        user?.role !== "superAdmin" ? (
        <Box>
          <UnderMaintenance
            dynamicSeoData={dynamicSeoData}
            defaultSupportData={defaultSupportData}
          />
        </Box>
      ) : (
        showComponent && (
          <ThemeProvider>
            <Box
              style={{
                height: newFlag || window.location.pathname == '/course-material/content' ? "0px" : "70px",
                padding: "0px",
                marginBottom: "0px",
              }}
              className={"container " + (openSidebar ? "open-drawer" : "")}
              onClick={
                windowScreenWidth <= screenWidth
                  ? closeDrawer.bind(this)
                  : () => null
              }
            >
              <HeaderWrapper
                open={openSidebar}
                handleDrawerOpen={openDrawer}
                handleDrawerClose={closeDrawer}
              />
            </Box>
            <Navigation
              openSidebar={openSidebar}
              windowScreenWidth={windowScreenWidth}
              screenWidth={screenWidth}
              closeDrawer={closeDrawer}
            >
              <Box className={`main-div ${window.location.pathname === '/login' || window.location.pathname === '/change-password' || window.location.pathname === '/forgot-password' ? 'mobile-view-password-spacing' : undefined}`}>
                {children}
              </Box>
            </Navigation>
            <BottomNavigationTabs />
          </ThemeProvider>
        )
      )}
    </Box>
  );
}

export default MainContent;
