import React from 'react';
import MathJax from "react-mathjax4";
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { editor } from 'examsbook-react-sdk/common';
import { Typography, Box } from '@material-ui/core';

/**
 * ExplanationSection of a question.
 * @param props
 * @returns {*}
 * @constructor
 */
function ExplanationSection(props) {
    const { viewMode, explanation } = props;
    const t = useTranslations();
    const classes = useStyles(styles)();

    // convert math type question readable format
    editor(explanation);
    
    return (
        <React.Fragment>
            {
                viewMode && explanation !== '<p>undefined</p>' && explanation !== 'undefined' ?
                    <Box className={classes.descbox}>
                        { explanation ? <Typography variant='h4'>{t('explanation')}</Typography> : null }
                        <Box>
                            <MathJax.Context input="tex">
                                <MathJax.Html html={explanation}/>
                            </MathJax.Context>
                        </Box>
                    </Box> : ''
            }
        </React.Fragment>
    );
}

const styles = theme => ({
    descbox: {
        '-webkit-user-select': 'none',
        '-webkit-touch-callout': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
        width: '100%',
        display: "inline-block",
        padding: '0 15px',
        boxSizing: 'border-box'
    }
});

export default ExplanationSection;
