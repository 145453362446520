import React from 'react';
import {Modal, Box} from '@material-ui/core';
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import { getQuizStatus, TimeUtils } from "examsbook-react-sdk/common";

/**
 * Score model screen
 * @param props
 * @returns {*}
 * @constructor
 */
function CheckScoreModal(props) {
    const classes = useStyles(styles)();
    const t = useTranslations();
    const { viewMode, duration, timeTaken, open, questions } = props;
    const { totalMarks, obtained } = getQuizStatus(questions);

    let timeSpent;
    if (viewMode) {
        timeSpent = TimeUtils.changeTimeFormat(timeTaken);
    }
    return (
        <Box className={classes.checkScoreModal}>
            {viewMode ?
                <Modal aria-labelledby="simple-modal-title"
                       aria-describedby="simple-modal-description"
                       open={open}
                       onClose={props.handlerCloseCheckScore}
                       className={classes.checkScorePopUp}
                >

                    <Box className={classes.totalMarksSection}>
                        <span className={classes.closeBtn} onClick={props.handlerCloseCheckScore}><i
                            className="fa fa-times"></i></span>
                        <Box className={classes.totalMarks}>
                            <span className={classes.totalMarksText}>{t('total_marks')}</span>
                            <span
                                className={classes.totalMarksContainer}>{parseFloat(obtained).toFixed(2) || 0} / {totalMarks || 0}</span>
                        </Box>
                        <Box className={classes.totalMarks + ' ' + classes.borderBottomNone}>
                            <span className={classes.totalMarksText}>{t('total_time')}</span>
                            <span
                                className={classes.totalMarksContainer}>{timeSpent ? timeSpent : '00:00'} / {duration ? duration : '00:00'} Mins</span>
                        </Box>
                    </Box>
                </Modal> : ''
            }
        </Box>
    );
}

const styles = theme => ({
    checkScoreModal: {
        display: 'none',
        '@media (max-width:767px)': {
            display: 'block'
        }
    },
    checkScorePopUp: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    totalMarksSection: {
        color: '#000',
        display: 'flex',
        alignItems: 'flex-start',
        marginLeft: 15,
        borderWidth: '1px',
        marginRight: '15px',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#fff',
        borderBottomColor: '#eee',
        width: 'auto',
        position: 'relative',
        margin: '0 auto'
    },
    totalMarks: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 0,
        padding: '25px 30px',
        width: 'calc(100% - 60px)',
        borderBottom: '1px solid #ddd'
    },
    totalMarksText: {
        width: 120,
        color: '#000',
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: 10,
    },
    totalMarksContainer: {
        padding: '2px 5px',
        display: 'flex',
        color: '#5F4BAB',
        backgroundColor: '#5f4bab21',
        borderRadius: 2,
        fontSize: 13,
        border: '1px solid #5F4BAB'
    },
    borderBottomNone: {
        borderBottom: '0'
    },
    closeBtn: {
        position: 'absolute',
        top: '8px',
        fontSize: 20,
        right: '8px',
        color: '#888'
    }
});
export default CheckScoreModal;
