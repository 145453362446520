import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import status from "../constants/actionStatus";
import { getSupportAction } from "../actions/getSupportAction";

/**
 * get list of dynamic seo settings
 * @returns {*}
 */
export default function useDyanmicSeoSettings(user) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSupportAction());
    }, [user]);
    let dynamicSeoSettingsState = useSelector((state) => state.dynamicSeoSettings);
    let isLoading = dynamicSeoSettingsState.status === status.PENDING;
    let dynamicSeoSettings = dynamicSeoSettingsState.dynamicSeoSettings;
    return {
        isLoading,
        dynamicSeoSettings,
    };
}
