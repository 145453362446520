import React, { useRef } from 'react';
import classNames from 'classnames';
import { CNavLink } from "./c/CHeader";
import './Header.scss';
import '../../../styles/_pages.scss';
import ReactImageFallback from "react-image-fallback";
import TopMenuList from "./TopMenuList";
import MobileMenuList from "./MobileMenuList";
import userDefaultImage from './../../../assets/images/student.png';
import { useSelector } from "react-redux";
import { useTranslations, useScreenWidth } from "examsbook-react-sdk/custom-hooks";
import useOnClickOutside from "../../../hooks/useClickOutside";
import { useTheme } from '../../../constants/themeProvider';
import { Typography, Box, Drawer, List } from '@material-ui/core';

/**
 * Main drawer component.
 * @param props
 * @returns {*}
 * @constructor
 */
function CDrawer(props) {
    const { classes, open, handleDrawerClose, openFeedbackModel,handleDrawerOpen } = props;
    const theme = useTheme();
    const ref = useRef();
    useOnClickOutside(ref, () => {
        if (screenWidth && screenWidth < 800) {
            handleDrawerClose();
        }
    });
    const t = useTranslations();
    const user = useSelector(state => state.user);
    const screenWidth = useScreenWidth();

    return (
        <Drawer className={`drawer-mobile`}
                variant="permanent"
                classes={{
                    paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}>
            <Box className={classes.drawerHeader} style={{marginTop:"-73px"}}>
                <Box className="drawer-close" onClick={handleDrawerClose}>
                    <i className="fa fa-times"/>
                </Box>
                <ReactImageFallback
                    src={userDefaultImage} // revert it to imageUrl once image upload allow.
                    fallbackImage={userDefaultImage}
                    initialImage={userDefaultImage}
                    alt="user image"
                    className="my-image main-logo"/>
            </Box>
            <Box className="user-section" >
                <Typography variant='h4'>{user.name}</Typography>

                <CNavLink to="/my-profile">
                    <i className="fa fa-pencil"
                       onClick={screenWidth && screenWidth < 800 ? handleDrawerClose : () => null}/>
                </CNavLink>
            </Box>
            <List className="listing">
                <TopMenuList openDrawerStatus={open} t={t} screenWidth={screenWidth}
                    handleDrawerClose={handleDrawerClose}
                    handleDrawerOpen={handleDrawerOpen}
                    classes={classes}
                    theme={theme}
                    user={user}
                />
                <Box className="mobile-menu-list">
                    <MobileMenuList t={t} openDrawerStatus={open} screenWidth={screenWidth}
                                    handleDrawerClose={handleDrawerClose} openFeedbackModel={openFeedbackModel}/>
                </Box>
            </List>
        </Drawer>
    );
}

export default CDrawer;
