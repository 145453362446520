import React, { useEffect, useState } from "react";
import "./Login.scss";
import { getDefaultSupportDataAction, getSupportAction } from "examsbook-react-sdk/actions/getSupportAction";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, Icon, Input, InputLabel, Link } from "@material-ui/core";
import { useTranslations } from "examsbook-react-sdk/custom-hooks";
import { useHistory } from "react-router-dom";
import { loginAction } from "examsbook-react-sdk/actions/loginAction";
import ReCAPTCHA from "react-google-recaptcha";
import LearnifyIcon from "../../assets/images/LearnifyLogoDark.png"
import { ReactComponent as MicrosoftIcon } from "../../assets/images/microsoft_logo.svg"
import { verifyDomainAction } from "examsbook-react-sdk/actions/verifyDomainAction";

const Login = () => {
  const [googleRecaptchaToggle, setGoogleRecaptchaToggle] = useState("");
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(null);
  const [formValues, setFormValues] = useState({username: "", password: ""});
  const [microsoftLoginSiteKey, setMicrosoftLoginSiteKey] = useState(false);
  const [googleRecaptchaSiteKey, setGoogleRecaptchaSiteKey] = useState(null);
  const [productName, setProductName] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [textColor, setTextColor] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [dynamicSeoSettingsData, setDynamicSeoData] = useState([]);
  const [defaultSeoSettingsData, setDefaultSupportData] = useState([]);
  const [subdomainName, setSubDomainName] = useState('');
  const dispatch = useDispatch();
  const t = useTranslations();
  const history = useHistory();
  const isMobileNumber = (str) => /^\d{10}$/.test(str);
  const containsOnlyNumbers = (str) => /^\d+$/.test(str);
  let inputValue;

  useEffect(() => {
    getSubdomain();
    getDynamicSeoSettingApiData();
    getDefaultSeoSettingApiData();
  }, []);

  const getDynamicSeoSettingApiData = async () => {
    try {
        dispatch(getSupportAction()).then((res) => {
          setDynamicSeoData(res);
        });
    } catch (error) {
      console.log('Error -', error.message);
    }
  };

  const getDefaultSeoSettingApiData = async () => {
    try {
      dispatch(getDefaultSupportDataAction()).then((res) => {
        setDefaultSupportData(res);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  }

  const getSubdomain = () => {
    const host = window.location.host;
    const domainParts = host.split('.');
    if (process.env.REACT_APP_TENANT_DOMAIN) {
      setSubDomainName(process.env.REACT_APP_TENANT_DOMAIN);
    }
    else if (domainParts.length > 3) {
      const domainName = domainParts[0];
      setSubDomainName(domainName);
    }
  };

  const handleDomainVerify = async (e) => {
    e.preventDefault();
    const { domainname } = formValues;

    const domainVerifyData = {
      subdomain: domainname,
      url: window.location.href,
    };
    try {
      dispatch(verifyDomainAction(domainVerifyData)).then((res) => {
        if (res?.isVerify && res?.url && res.success === "success") {
          window.location.href = res.url;
        }
        if (!res) {
          document.getElementById('domain-name-error').innerHTML = `<div class="errorMsg">Invalid domain</div>`;
          document.getElementById('domain-name-error').style.display = 'block';
          return;
        }
      })
    }
    catch (error) {
      console.log('Error -', error.message);
      document.getElementById("domain-name-error").innerHTML = `<div class="errorMsg">An unexpected error occurred. Please try again later.</div>`;
      document.getElementById("domain-name-error").style.display = "block";
      return;
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = formValues;

    if (googleRecaptchaToggle === "true" && !googleRecaptchaToken) {
      const resetPasswordError = document.getElementById('reset-password-error');
      if (resetPasswordError) {
        resetPasswordError.innerHTML = `<div class="errorMsg">Please validate the captcha first.</div>`;
        resetPasswordError.style.display = 'block';
      }
      return;
    }


    const loginData = {
      password,
      subdomain: subdomainName,
      [isNaN(+username) ? 'email' : 'mobileNumber']: username,
      ...(googleRecaptchaToggle === "true" && { captcha: googleRecaptchaToken }),
    };
    try {
      dispatch(loginAction(loginData)).then((res) => {
        if (res?.newPasswordRequired) {
          history.push('/change-password', loginData);
          return;
        }
        if (res?.token) {
          localStorage.setItem('token', res?.token);
          history.push('/dashboard');
          return;
        }
        if (res?.status === 400) {
          document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${res?.data?.message}</div>`;
          document.getElementById('reset-password-error').style.display = 'block';
          document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
          return;
        }
      })
    }
    catch (error) {
      console.log('Error -', error.message);
    }
  }

  useEffect(() => {
    const defaultMicrosoftToggle = defaultSeoSettingsData?.data?.Login?.find((image) => image.key === "Microsoft Login")?.defaultValue;
    const dynamicMicrosoftToggle = dynamicSeoSettingsData?.data?.find((item) => item.key === "Microsoft Login")?.value;
    const defaultGoogleRecaptchaKey = defaultSeoSettingsData?.data?.['Google Recaptcha'][0]?.defaultValue;
    const dynamicGoogleRecaptchaKey = dynamicSeoSettingsData?.data?.find(item =>  item.key === 'Google Recaptcha')?.value;
    const defaultProductName = defaultSeoSettingsData?.data?.['Product Name'][0]?.defaultValue;
    const dynamicProductName = dynamicSeoSettingsData?.data?.find(item =>  item.key === 'Product Name')?.value;
    const defaultGoogleRecaptchaValue = defaultSeoSettingsData?.data?.['Recaptcha']?.find((item) => item.key === "Site Key")?.defaultValue;
    const dynamicGoogleRecaptchaValue = dynamicSeoSettingsData?.data?.find(item =>  item.key === 'Site Key')?.value;
    setMicrosoftLoginSiteKey(dynamicMicrosoftToggle === "true" ? dynamicMicrosoftToggle : defaultMicrosoftToggle);
    setGoogleRecaptchaToggle(dynamicGoogleRecaptchaKey === "true" ? dynamicGoogleRecaptchaKey : defaultGoogleRecaptchaKey);
    setProductName(dynamicProductName ? dynamicProductName : defaultProductName)
    setBgColor("#0C3450");
    setTextColor("#fff");
    setGoogleRecaptchaSiteKey(dynamicGoogleRecaptchaValue ? dynamicGoogleRecaptchaValue : defaultGoogleRecaptchaValue)
  },[defaultSeoSettingsData, dynamicSeoSettingsData]);

  const handleEmailChange = (event) => {
    inputValue = event.target.value;
    const isValidEmail = (email) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase());
    let errMsg = "Enter Valid Credentials";
    let emailValid = true;

    handleChange('username', inputValue);
    handleActiveLoginButton(inputValue, formValues.password, googleRecaptchaToken);
    if (!isValidEmail(inputValue)) {// check if we want this function
      focusTextField("loginEmail");
      errMsg = "Enter Valid Credentials";
      emailValid = false;
    }

    if (
      (containsOnlyNumbers(inputValue) && !isMobileNumber(inputValue)) ||
      (!containsOnlyNumbers(inputValue) && !isValidEmail(inputValue))
    ) {
      errMsg = "Enter Valid Credentials";
      emailValid = false;
    }

    if (!emailValid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }

  };

  const handleDomainName = (event) => {
    inputValue = event.target.value;
    handleChange('domainname', inputValue);
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    history.push('/forgot-password');
  };

  const handleMicrosoftLogin = () => {
    let siteUrl = window.location.href.split('?');
    let queryParams = siteUrl[1];
    var microsoftUrl = "/auth/microsoft";
    if (queryParams && queryParams.return_to) {
      microsoftUrl += "?return_to=" + queryParams.return_to;
    }
  };

  const handlePasswordChange = (event) => {
    let password = event.target.value;
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let errMsg = "Enter min 8 letters, with at least a symbol, upper and lower case letters and a number";
    const captchaContainer = document.getElementById("captchaContainer");
    const loginError = document.getElementById("login-error");
    const loginButton = document.getElementById("login-button");
    handleChange('password', password);
    handleActiveLoginButton(formValues.username, password, googleRecaptchaToken);

    let passValid = true;
    if (!regex.test(event.target.value)) {
      errMsg = "Enter min 8 letters, with at least a symbol, upper and lower case letters and a number";
      passValid = false;
    }

    if (!passValid) {
      document.getElementById('reset-password-error').innerHTML = `<div class="errorMsg">${errMsg}</div>`;
      document.getElementById('reset-password-error').style.display = 'block';
      document.getElementById('changePasswordBtn').setAttribute("disabled", "disabled");
    } else {
      document.getElementById('reset-password-error').style.display = 'none';
      document.getElementById('changePasswordBtn').removeAttribute("disabled");
    }

    if (password === "12345") {
      if (googleRecaptchaToggle === "true" && captchaContainer) {
        captchaContainer.style.display = "none";
      }
      setPasswordError("");
      setIsButtonDisabled(false);
    }
    if(password.length == 6 && isMobileNumber(formValues.username)) {
      setPasswordError("");
      setIsButtonDisabled(false);
      document.getElementById('reset-password-error').style.display = 'none';
    }
    else {
      if (!regex.test(password)) {
        setPasswordError(errMsg);
        setIsButtonDisabled(true);
      } else {
        if (googleRecaptchaToggle === "true" && captchaContainer) {
          captchaContainer.style.display = "block";
        }
        setPasswordError("");
        setIsButtonDisabled(false);
      }
    }

    // Update DOM elements dynamically for non-React-managed attributes
    if (loginError) {
      if (passwordError) {
        loginError.innerHTML = `<div class="errortext">${passwordError}</div>`;
        loginError.style.display = "block";
      } else {
        loginError.style.display = "none";
      }
    }

    if (loginButton) {
      if (isButtonDisabled) {
        loginButton.setAttribute("disabled", "disabled");
      } else {
        loginButton.removeAttribute("disabled");
      }
    }
  };

  const focusTextField = (fieldId) => { // check if we want it.
    const field = document.getElementById(fieldId);
    if (field) {
      field.focus();
    }
  };

  const togglePasswordVisibility = (inputId, iconId) => {
    setShowPassword((prev) => !prev); // This updates the React state
    const passwordInput = document.getElementById(inputId);
    const icon = document.getElementById(iconId);
  if (passwordInput) {
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
        return icon?.classList?.replace("fa-eye-slash", "fa-eye");
      } else {
        passwordInput.type = "password";
        return icon?.classList?.replace("fa-eye", "fa-eye-slash");
      }
    } else {
      console.error(`Input element with id "${inputId}" not found.`);
    }
  };

  const preventSpaceKey = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  };
  const handleRecaptchaChange = (token) => {
    setGoogleRecaptchaToken(token);
    handleActiveLoginButton(formValues.username, formValues.password, token);
  };

  const handleActiveLoginButton = (username, password, recaptchaToken) => {
    const isValid = username && password && recaptchaToken;
    setIsButtonDisabled(!isValid);
  };

    return (
       <>
        <Grid className="login-parent-div">
          <Grid className="login-child-div">
            <Box component="img" id="login-img-learn" className="login-logo" src={LearnifyIcon} alt={productName} />
          </Grid>
          {!subdomainName &&
          // domain verification screen
          <Grid className="login-right-side-box">
          <Box className="verify-domain" style={{ color: `${bgColor}` }}>{t('Verify Domain')}</Box>
          <Box className="form-group error-msg" id="domain-name-error"></Box>
          <Box component="form" id="form-login" name="loginForm" className="form-input-data form-horizontal" onSubmit={handleDomainVerify} method="post">
            <Box>
              <Box className="mdc-text-field mdc-text-field--outlined email-field">
                <Box 
                sx={{display: 'flex', alignItems: 'center', fontSize: '14px', gap: '8px', justifyContent: 'center'}}>
                  <Input type="text" value={formValues.domainname} className="domain-input-box input-box required_text" id="domainField" autoComplete="off" placeholder="Domain name" onChange={(e) => handleDomainName(e)} onKeyDown={preventSpaceKey} />
                  <Box className="domain-name">.{window.location.host}</Box>
                </Box>
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
              <Box className="parent-login-button-div change-domain-name-submit-btn">
               <Button
                  type="submit"
                  id="changePasswordBtn"
                  className={`login-new-btn ${!formValues?.domainname ? 'disabled-btn' : ''}`}
                  style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
                  disabled={!formValues?.domainname}
                >
                    {t('Verify')}
                </Button>
              </Box>
          </Box>
          </Grid>
          }
          {subdomainName && <Grid className="login-right-side-box">
          <Box className="login-title" style={{ color: `${bgColor}` }}>{t('Login')}</Box>
          <Box className="form-group error-msg" id="reset-password-error"></Box>
          <Box component="form" id="form-login" name="loginForm" className="form-horizontal" onSubmit={handleLogin} method="post">
            <Box>
              <Box className="mdc-text-field mdc-text-field--outlined email-field">
                <Box className="mdc-notched-outline__notch">
                  <InputLabel style={{color: bgColor}} className="mdc-text-field__label" htmlFor="loginEmail"
                    >{t('Email/Mobile No.')}</InputLabel>
                </Box>
                <Input type="text" value={formValues.username} className="input-box required_text" id="loginEmail" autoComplete="off" placeholder="Email/Mobile No." onChange={handleEmailChange} onKeyDown={preventSpaceKey} />
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
            <Box style={{position: 'relative'}}>
              <Box className="mdc-text-field mdc-text-field--outlined removeTopGap">
                <InputLabel style={{color: bgColor}} className="mdc-text-field__label" htmlFor="loginPassword">{t('Password')}</InputLabel>
                <Input type={showPassword ? "text" : "password"} value={formValues.password} className="input-box required_text"
                id="loginPassword" autoComplete="off" placeholder="Password" onChange={handlePasswordChange} onKeyDown={preventSpaceKey} />
                <Box className="mdc-notched-outline">
                  <Box className="mdc-notched-outline__leading"></Box>
                  <Box className="mdc-notched-outline__trailing"></Box>
                </Box>
                <Icon className="fa fa-eye-slash toggle-password-icon" id="toggleLoginPassword" onClick={() => togglePasswordVisibility('loginPassword', 'toggleLoginPassword')}></Icon>
              </Box>
              <Box className="help-block with-errors"></Box>
            </Box>
            <Box className="login-forgot-password-div">
              <Link id="forgot-password" onClick={handleForgotPassword} className="login-forgot-password-text" style={{color: bgColor}} >{t('Forgot Password')}</Link>
            </Box>
              {googleRecaptchaToggle === "true" &&
                <ReCAPTCHA id="captchaContainer" className="g-recaptcha" sitekey={googleRecaptchaSiteKey} onChange={handleRecaptchaChange} />
              }
              <Box className="parent-login-button-div">
               <Button
               type="submit"
               id="changePasswordBtn"
               className={`login-new-btn ${isButtonDisabled ? 'disabled-btn' : ''}`}
              style={{backgroundColor: `${bgColor}`, color: `${textColor}`}}
               disabled={isButtonDisabled} // Add the disabled attribute
           >
               {t('Login')}
           </Button>
                {/* } */}
                {microsoftLoginSiteKey === "true" && <Button className="login-microsoft-div" style={{backgroundColor: `${bgColor}`, color: `${textColor}`}} type="button" onClick={handleMicrosoftLogin}>
                <MicrosoftIcon className="login-microsoft-button-icon" alt="micosoft logo"/>
                {t("Login with Microsoft")}
                  </Button>}
              </Box>
          </Box>
          </Grid>}
        </Grid>
       </>
    );
};

export default Login;
