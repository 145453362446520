import React, { useState, useEffect } from 'react';
import QuizSidebarLoader from "./../Loader/QuizSidebarLoader";
import 'react-confirm-alert/src/react-confirm-alert.css';
import classNames from 'classnames';
import { Drawer, Box } from '@material-ui/core';
import QuizSectionMap from "./QuizSectionMap";
import QuizStatus from "./QuizStatus";
import QuizCompactResult from "./QuizCompactResult";
import { useSelector } from "react-redux";
import { useScreenWidth, useStyles } from "examsbook-react-sdk/custom-hooks";

const drawerWidth = 320;

/**
 * QuizSidebar
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizSidebar(props) {
    const {
        viewMode, selectPaletteQuestion,
        result, isLoading, timeTaken, changeSectionActive, duration, handler, drawerToggle, startDate, endDate, quiz
    } = props;
    const classes = useStyles(styles)();
    const [ openIndex, setOpenIndex ] = useState(0);
    const questions = useSelector((state) => state.quiz.questions);
    const screenWidth = useScreenWidth();

    useEffect(() => {
        if (openIndex !== changeSectionActive) {
            setOpenIndex(changeSectionActive);
        }
    }, [ changeSectionActive, openIndex ]);

    function drawerClose() {
        handler();
    }

    function mobileDrawerClose() {
        if (screenWidth <= 800) {
            handler();
        }
    }

    function handleSectionChange(i) {
        if (openIndex === i) {
            setOpenIndex('');
        } else {
            setOpenIndex(i);
        }
    }

    let counter = 0;
    return (
        <Box>
            <Box className={classNames(classes.sidebarIconRight)} onClick={drawerClose}>
                <i className="fa fa-arrow-right"/>
            </Box>
            {screenWidth > 767 && screenWidth < 1025 ?
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: !drawerToggle ? classes.drawerClosed : classes.drawerPaper
                    }}
                >
                    <Box>
                        <Box className={classes.questionNumberBlock}>
                            <Box className={classNames(classes.sidebarIconLeft)}>
                                <i className="fa fa-arrow-left" onClick={drawerClose}/>
                            </Box>
                            {
                                !isLoading ? <React.Fragment>
                                    <QuizCompactResult viewMode={viewMode} questions={questions} timeTaken={timeTaken}
                                                       duration={duration} startDate={startDate} endDate={endDate}/>
                                    <QuizStatus result={result} viewMode={viewMode} questions={questions}/>
                                    <Box className={classNames(classes.quizSidebarAccordion)}
                                         onClick={mobileDrawerClose}>
                                        {
                                            questions.map((item, i) => {
                                                if (i > 0) {
                                                    counter += questions[i - 1].questions.length;
                                                }
                                                return (
                                                    <QuizSectionMap key={i} index={i} counter={counter} item={item}
                                                                    selectPaletteQuestion={selectPaletteQuestion}
                                                                    viewMode={viewMode} openIndex={openIndex} quiz={quiz}
                                                                    handleSectionChange={handleSectionChange}/>
                                                );

                                            })
                                        }
                                    </Box>

                                </React.Fragment> : <Box><QuizSidebarLoader/></Box>
                            }

                        </Box>
                        {/* <InstructionsButton handler={handler} viewMode={viewMode} mode={mode} submitQuiz={submitQuiz}  toggleInstructionBox={toggleInstructionBox}/>*/}
                    </Box>
                </Drawer>
                :
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: drawerToggle ? classes.drawerClosed : classes.drawerPaper
                    }}
                >
                    <Box>
                        <Box className={classes.questionNumberBlock}>
                            <Box className={classNames(classes.sidebarIconLeft)}>
                                <i className="fa fa-arrow-left" onClick={drawerClose}/>
                            </Box>
                            {
                                !isLoading ? <React.Fragment>
                                    <QuizCompactResult viewMode={viewMode} questions={questions} timeTaken={timeTaken}
                                                       duration={duration} startDate={startDate} endDate={endDate}/>
                                    <QuizStatus result={result} viewMode={viewMode} questions={questions}/>
                                    <Box className={classNames(classes.quizSidebarAccordion)}
                                         onClick={mobileDrawerClose}>
                                        {
                                            questions.map((item, i) => {
                                                if (i > 0) {
                                                    counter += questions[i - 1].questions.length;
                                                }
                                                return (
                                                    <QuizSectionMap key={i} index={i} counter={counter} item={item}
                                                                    selectPaletteQuestion={selectPaletteQuestion}
                                                                    viewMode={viewMode} openIndex={openIndex} quiz={quiz}
                                                                    handleSectionChange={handleSectionChange}/>
                                                );

                                            })
                                        }
                                    </Box>

                                </React.Fragment> : <Box><QuizSidebarLoader/></Box>
                            }

                        </Box>
                        {/*<InstructionsButton handler={handler} viewMode={viewMode} mode={mode} submitQuiz={submitQuiz}  toggleInstructionBox={toggleInstructionBox}/>*/}
                    </Box>
                </Drawer>
            }
        </Box>
    );
}

const styles = theme => ({
    sidebarIconLeft: {
        textAlign: 'right',
        padding: 10,
        color: '#000',
        position: 'absolute',
        top: 16,
        right: 4,
        zIndex: 9,
        '& i': {
            cursor: 'pointer'
        },
        '@media (max-width: 767px)': {
            display: 'none'
        }
    },
    quizSidebarAccordion: {
        '@media (max-width: 767px)': {
            marginLeft: 10,
            marginRight: 10,
            marginTop: 50,
            paddingBottom: 30
        },
        '@media (min-width: 280px) and (max-width: 425px)': {
            marginTop: 155,
        },
        '@media (min-width: 426px) and (max-width: 660px)': {
            marginTop: 200,
        },
        '@media (min-width: 661px) and (max-width: 767px)': {
            marginTop: 230,
        },
        '@media (min-width: 768px) and (max-width: 800px)': {
            marginTop: 290,
            position: 'fixed',
            width: '-webkit-fill-available'

        },
        '& > div': {
            boxShadow: 'none',
            marginBottom: '15px !important',
            backgroundColor: '#fff',
        }
    },
    marginTop: {
        marginTop: 10,
    },
    questionNumberBlock: {
        height: 'calc(100vh - 66px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d3d5d3'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#eeecec'
        },
        '@media (max-width: 800px)': {
            height: 'calc(100vh - 50px)',
        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        zIndex: 0,
        width: drawerWidth,
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderLeftColor: '#ddd',
        height: 'auto',
        overflow: 'hidden',
        transition: '0.3s ease-in-out',

        '@media (max-width:767px)': {
            width: '100%',
            top: 55,
            bottom: 0,
            opacity: 1,
            zIndex: 1000
        },
        '@media (max-width: 1100px) and (min-width: 768px)': {
            zIndex: 99,
        }
    },
    drawerClosed: {
        width: drawerWidth,
        top: 0,
        bottom: 10,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderLeftColor: '#ddd',
        boxShadow: '0 0 15px #aaa',
        height: 'auto',
        transition: '0.3s ease-in-out',
        overflow: 'hidden',
        transform: 'translate(-330px)',
        '@media (max-width:800px)': {
            width: '100%',
            top: 55,
            zIndex: -1000,
            bottom: 0,
            transform: 'unset',
            opacity: 0
        }
    },
    sidebarIconRight: {
        position: 'fixed',
        top: 16,
        left: 0,
        padding: 10,
        backgroundColor: '#fff',
        boxShadow: '0 0 15px #aaa',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        cursor: 'pointer',
        '@media(max-width:800px)': {
            display: 'none'
        }
    }
});
export default QuizSidebar;

