import React, { useState } from 'react';
import { useSelector } from "react-redux";
import QuizFooterLoader from "./../Loader/QuizFooterLoader";
import { useTranslations, useStyles } from "examsbook-react-sdk/custom-hooks";
import CheckScoreModal from "../CheckScoreModal/checkScoreModal";
import OverlayButton from '../../../constants/OverlayButton';
import {Box} from '@material-ui/core';

/**
 * QuizFooter
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizFooter(props) {
    const { isLoading, viewMode, hideNext, handleClick, drawerToggle, hidePrev, handleQuizPauseChange, setReset } = props;
    const quiz = useSelector((state) => state.quiz);
    const { duration, timeTaken, selectTimeFor } = quiz;
    const t = useTranslations();
    const selectedQuestion = useSelector((state) => state.selectedQuestion);
    const markButtonActive = selectedQuestion ? selectedQuestion.marked : false;
    const classes = useStyles(styles)();
    const questions = useSelector((state) => state.quiz.questions);
    const [ openScoreModel, setOpenScoreModel ] = useState(false);

    function handlerOpenCheckScore() {
        setOpenScoreModel(true);
    }

    function handlerCloseCheckScore() {
        setOpenScoreModel(false);
    }

    return (
        !isLoading ?
            <React.Fragment>
                <CheckScoreModal viewMode={viewMode} open={openScoreModel} questions={questions}
                                 handlerCloseCheckScore={handlerCloseCheckScore} duration={duration}
                                 timeTaken={timeTaken}/>
                <Box className={`${classes.quizFooter} ${drawerToggle ? classes.quizFooterExpand : null}`}>

                    {viewMode ?
                        <Box className={markButtonActive ? classes.scorebtn + ' ' + classes.active : classes.scorebtn}>
                            <span className={classes.markForReview + ' ' + classes.checkScore}
                                  onClick={handlerOpenCheckScore}>Your Score</span>
                        </Box> : ''}

                    <Box className={classes.prevNextBtns}>
                        {!hidePrev && (!hideNext || selectTimeFor === "QUIZ")?
                            <span className={classes.prevBtn}
                                  onClick={() => {
                                    if(selectTimeFor === "QUESTION"){
                                        handleClick('next');
                                        handleQuizPauseChange(false);
                                        setReset(prev => !prev);
                                    } else {
                                        handleClick('prev');
                                        handleQuizPauseChange(false);
                                    }
                                  }}>
                                      {selectTimeFor === "QUESTION" && !hideNext && <span className={classes.prevTextAlign}>{t('Skip')}</span>}
                                      {selectTimeFor === "QUIZ" && <span className={classes.prevTextAlign}>{t('Previous')}</span>}
                            </span> :
                            <span className="prev disabled" style={{ left: 0, position: "absolute" }}></span>
                        }

                        {(viewMode) && hideNext ? "" : 
                        <OverlayButton onClick={() => {
                            handleClick('next');
                            handleQuizPauseChange(false);
                            if(selectTimeFor === "QUESTION") {
                                setReset(prev => !prev);
                            }
                        }}  title={!hideNext ? t('Next') : t('Submit') }  classStyleHeading={(viewMode || hideNext) ? classes.submitBtn : classes.nextBtn} classStyleText={classes.confirmText}>
                            {!hideNext && <i className="fa fa-arrow-right"/>}
                        </OverlayButton>
                    }
                    </Box>
                </Box>
            </React.Fragment> : 
            <Box className={classes.quizFooter}>
                <QuizFooterLoader/>
            </Box>
    );
}

const styles = theme => ({
    quizFooter: {
        height: 44,
        position: 'fixed',
        bottom: 20,
        right: "7%",
        left: 340,
        color: '#fff',
        border: '1px',
        borderColor: 'black',
        borderRadius: '1px',
        transition: '0.3s all linear',
        padding: 0,
        overflow: 'hidden',
        zIndex: 9,
        backgroundColor: 'transparent',
        display: 'inline-block',
        textAlign: 'center',
        '@media (min-width:280px) and (max-width:767px)': {
            bottom: 15,
            right: 0,
            left: '0 !important',
            borderRadius: 0,
            display: 'flex !important',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            padding: '0 0 10px',
        },
        '@media (min-width:768px) and (max-width:1024px)': {
            left: '-3%',
        },
    },
    quizFooterExpand: {
        left: 10,
        '@media (min-width:768px) and (max-width:1023px)': {
           left: '350px',
           zIndex: 9
        },
        '@media (min-width:1024px) and (max-width:1024px)': {
            left: '310px'
        },
    },
    prevTextAlign:{
        '@media (min-width:280px) and (max-width:767px)': {
            paddingLeft: '4px'
        },
    },
    markBtn: {
        float: 'none',
        width: 'auto',
        textAlign: 'left',
        backgroundColor: '#fff',
        display: 'inline-block',
        '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
        }
    },
    scorebtn: {
        float: 'none',
        width: 'auto',
        textAlign: 'left',
        backgroundColor: '#fff',
        display: 'inline-block',
        '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
            display: 'none'
        }
    },
    active: {
        ConvolverNode: '#0b2f8a',
        '& span': {
            color: '#fff',
        }
    },
    markForReview: {
        width: 'auto',
        display: 'inline-block',
        padding: 14,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 5,
        textAlign: 'center',
        fontSize: 14,
        marginLeft: 0,
        cursor: 'pointer',
        fontWeight: 600,
        color: '#0039cb',
        '@media (max-width:767px)': {
            paddingLeft: 0,
            padding: 0,
            paddingRight: 0,
            fontSize: 13,
        }
    },
    checkScore: {
        display: 'none',
        '@media (max-width:767px)': {
            display: 'block'
        }
    },

    prevNextBtns: {
        float: 'right',
        width: 'auto',
        textAlign: 'right',
        backgroundColor: '#fff',
        '@media (max-width: 767px)': {
            backgroundColor: 'transparent',
            width: '100%',
            padding: '0 10px',
            display: 'flex'
        }
    },
    prevBtn: {
        display: 'inline-block',
        padding: '6px 20px',
        textAlign: 'center',
        fontSize: 16,
        cursor: 'pointer',
        color: '#000',
        position: 'absolute',
        left: '10%',
        fontWeight: '400',
        border: '1px solid #000000B2',
        borderRadius: 4,
        justifyContent: 'center',
        width: 'max-content',
        '&:hover': {
            color: '#000',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '@media (min-width:280px) and (max-width:767px)': {
            padding: 12,
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 16,
            position: 'static',
            width: '40%',
            marginRight: 'auto',
            float: 'left',
            boxSizing: 'border-box',
            display: 'flex',
            minHeight: 44,
            alignItems: 'center'
        },
        '@media (min-width: 1900px)': {
            left: "80px",           
        }
    },
    nextBtn: {
        display: 'inline-block',
        padding: '6px 20px',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: 16,
        cursor: 'pointer',
        borderRadius: 4,
        color: '#000000',
        fontWeight: '500',
        position: 'absolute',
        background: '#ffffff',
        right: 0,
        width: '100px',
        '& i': {
            marginLeft: 5,
            position: 'relative',
            top: -1
        },
        '&:hover': {
            color: '#000000',
            cursor: 'pointer',
            backgroundColor: '#0c3450e0 !important',
        },
        '@media (min-width:280px) and (max-width:767px)': {
            padding: 12,
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 16,
            position: 'static',
            width: '40%',
            marginRight: 0,
            marginLeft: 'auto',
            float: 'right',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 44
        },
    },
    skipBtn: {
        color: '#000',
        '&:hover': {
            color: '#000 !important'
        }
    },
    submitBtn: {
        color: '#FAFAFA',
        backgroundColor: '#0C3450',
        display: 'inline-block',
        padding: '6px 20px',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: 16,
        cursor: 'pointer',
        minWidth: 'max-content',
        borderRadius: 4,
        fontWeight: '500',
        position: 'absolute',
        background: '#ffffff',
        right: 0,
        width: '100px',
        '& i': {
            marginLeft: 5,
            position: 'relative',
            top: -1
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#0c3450e0 !important',
        },
        '@media (min-width:280px) and (max-width:767px)': {
            padding: 12,
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 16,
            position: 'static',
            width: '50%',
            marginRight: 0,
            marginLeft: 'auto',
            float: 'right',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 44
        },
    },
    disabled: {
        pointerEvents: 'none',
        color: '#888F96',
        '& i': {
            opacity: 0.5
        }
    }
});
export default QuizFooter;
