import React, { useEffect, useRef } from 'react';
import { List, ListItem, Box } from '@material-ui/core';
import useStyles from "examsbook-react-sdk/custom-hooks/useStyles";
import { useSelector } from "react-redux";

/**
 * Question map.
 * @param props
 * @returns {*}
 * @constructor
 */
function QuestionsMap(props) {
    const { counter, index, viewMode, questions, quiz } = props;
    const activeQuestionIndex = useSelector((state) => state.activeQuestionIndex);
    const classes = useStyles(styles)();
    const activeQuestionRef = useRef(null);
    const scrollToBottom = () => (
        activeQuestionRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    );
    const { selectTimeFor } = quiz;

    useEffect(() => {
        scrollToBottom();
    }, [ activeQuestionIndex ]);

    function getQuestionStatusWithClass(question, isActive) {
        let className = '';
        if (question.answered && !question.marked) {
            className = classes.right;
        } else if (question.marked && !question.answered) {
            className = classes.marked;
        } else if (!question.answered && question.visited) {
            className = classes.wrong;
        } else if (question.marked && question.answered) {
            className = classes.markedAnswered;
        } else if (!question.visited) {
            className = classes.notVisited;
        }
        if (isActive) {
            className = classes.active;
        }
        return className;
    }

    function getQuestionResultWithClass(question, isActive) {
        let className = classes.right;
        if (question && question.id && question.id.options && question.id.options.length) {
            for (const option of question.id.options) {
                if (isActive) {
                    className = classes.active;
                } else if (!question.visited) {
                    className = classes.notVisited;
                } else if (question.visited && !question.answered) {
                    className = classes.notAnswered;
                } else if (question.answered && (question.givenAnswer[0] === option._id) && option.correct) {
                    className = classes.right;
                } else if ((question.answered && (question.givenAnswer[0] === option._id) && !option.correct)) {
                    className = classes.wrong;
                }
            }
        }
        return className;
    }

    return (
        <Box key={index}>
            <List className={classes.questionList}>{
                questions.map((question, i) => {
                    let vIndex = counter + i;
                    let viewClassName = viewMode ? getQuestionResultWithClass(question, activeQuestionIndex === vIndex) : getQuestionStatusWithClass(question, activeQuestionIndex === vIndex);

                    return activeQuestionIndex === vIndex ? <ListItem ref={activeQuestionRef}
                                                                      className={classes.backgroundQuestionNumber + ' ' + viewClassName}
                                                                      style={{ cursor: selectTimeFor === "QUIZ" ? 'pointer' : 'default' }}
                                                                      key={vIndex}
                                                                      onClick={selectTimeFor === "QUIZ" ? props.selectPaletteQuestion.bind(this, vIndex) : undefined}
                                                                      ><span>{vIndex + 1}</span><i
                            className="fa fa-check"></i> </ListItem> :
                        <ListItem className={classes.backgroundQuestionNumber + ' ' + viewClassName}
                                  style={{ cursor: selectTimeFor === "QUIZ" ? 'pointer' : 'default' }}
                                  key={vIndex}
                                  onClick={selectTimeFor === "QUIZ" ? props.selectPaletteQuestion.bind(this, vIndex) : undefined}
                                  ><span>{vIndex + 1}</span><i
                            className="fa fa-check"></i> </ListItem>;
                })
            }
            </List>
        </Box>
    );
}

const styles = theme => ({
    right: {
        backgroundColor: '#5DF2D60C',
        border: '1px solid #27AE60',
        '& i': {
            display: 'none'
        }
    },
    notAnswered: {
        backgroundColor: '#FFC6580F',
        border: '1px solid #FFC658',
        '& i': {
            display: 'none'
        }
    },
    wrong: {
        backgroundColor: '#bf060612',
        border: '1px solid #BF0606',
        '& i': {
            display: 'none'
        }
    },
    notVisited: {
        backgroundColor: '#e1e2e12b',
        border: '1px solid #e1e2e1',
        color: '#000',
        '& span': {
            color: '#000',
        },
        '& i': {
            display: 'none'
        }
    },
    active: {
        backgroundColor: '#0B2F8A0F',
        border: '1px solid #0B2F8A',
        '& i': {
            display: 'none'
        }
    },
    markedAnswered: {
        backgroundColor: '#0b2f8a29',
        border: '1px solid #0b2f8a',
        position: 'relative',
        '& i': {
            position: 'absolute',
            right: -4,
            top: -4,
            color: '#27ae60',
            fontSize: 16,
            '@media (max-width:767px)': {
                top: -8,
                fontSize: 22
            }
        }
    },
    marked: {
        backgroundColor: '#0b2f8a29',
        border: '1px solid #0b2f8a',
        '& i': {
            display: 'none'
        }
    },
    questionList: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        '&::-webkit-scrollbar': {
            width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d3d5d3'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#eeecec'
        },
        '@media (max-width: 767px)': {
            justifyContent: 'flex-start'
        }
    },
    backgroundQuestionNumber: {
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        padding: 0,
        color: '#000',
        fontSize: 12,
        fontWeight: 'bold',
        justifyContent: 'center',
        marginRight: 15,
        marginBottom: 15,
        cursor: 'default',
        '@media (max-width: 374px)': {
            width: '13vw !important',
            height: '13vw !important',
            marginRight: 20,
            marginBottom: 20,
        },
        '@media (max-width: 767px)': {
            width: '14vw',
            height: '14vw',
            marginRight: 10,
            marginBottom: 10,
        },
        '@media (min-width: 768px) and (max-width: 800px)': {
            width: '14vw',
            height: '14vw',
            marginRight: 10,
            marginBottom: 10,
        },
        '&:nth-child(5n)': {
            marginRight: 0,
        },
        '& span': {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
});
export default QuestionsMap;
