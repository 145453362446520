import status from "../constants/actionStatus";

export default (
    state = {
        selfLearningData: {},
        dataId: null,
        sectionId: null
    },
    action = {}
) => {
    switch (action.type) {
    case "SELF_LEARNING_REQUEST":
        return {
            status: status.PENDING,
            selfLearningData: {},
        };
    case "SELF_LEARNING_SUCCESS":
        return {
            status: status.SUCCESS,
            selfLearningData: action.payload,
        };

    case "SELF_LEARNING_FAILURE":
        return {
            status: status.ERROR,
            selfLearningData: {},
        };
    case "CLEAR_SELF_LEARNING":
        return { status: status.PENDING, selfLearningData: {} };
    case "SET_DATA_ID":
        return {
            ...state,
            dataId: action.payload,
        };
    case "SET_SECTION_ID":
        return {
            ...state,
            sectionId: action.payload,
        };
    default:
        return state;
    }
};
