import React, { useEffect } from 'react';
import QuizTimer from "./QuizTimer";
import QuizHeaderLoader from "./../Loader/QuizHeaderLoader";
import LanguageSwitcher from "../../common/Header/LanguageSwitcher";
import { useStyles } from "examsbook-react-sdk/custom-hooks";
import { useSelector } from "react-redux";
import SubmitButton from "./SubmitButton";
import { fullScreenMode } from "examsbook-react-sdk/common";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import QuestionQuiz from "../../../assets/images/QuizIcon.png"
import pauseImage from "../../../assets/images/ShadowPauseButton.png"
import mobilePause from "../../../assets/images/mobilePause.png"
import moment from 'moment';
import {Box} from '@material-ui/core';

/**
 * Quiz header
 * @param props
 * @returns {*}
 * @constructor
 */
function QuizHeader(props) {
   
    const {
        quizPage, name, viewMode, mode, submitQuiz, drawerToggle, duration, usedTime,
        setTimeTaken, isLoading, toggleDrawerHandler, pauseQuiz, handleQuizPauseChange, slug, startDate, endDate, totalQuestions, selectTimeFor, handleClick, hideNext, reset, setReset
    } = props;
    const history = useHistory();
    const quiz = useSelector((state) => state.quiz);
    const classes = useStyles(styles)();
    const headerClass = quizPage && drawerToggle ? classes.quizHeaderExpand : classes.quizHeader;
    const quizStartDate = new Date();
    const formattedStartDate = moment(quizStartDate).format("DD-MM-YYYY HH:mm")
    const formattedEndDate = moment(endDate).format("DD-MM-YYYY HH:mm")
    const convertedQuizEndDate = moment(formattedEndDate, "DD-MM-YYYY HH:mm:ss");
    const convertedQuizCurrentDateTime =  moment(formattedStartDate, "DD-MM-YYYY HH:mm:ss");
    const difference = moment.duration(convertedQuizEndDate.diff(convertedQuizCurrentDateTime));
    const totalMinutes = (difference.days() * 24 * 60) + (difference.hours() * 60) + difference.minutes();
    const newTime = mode === 'mock-test' ? selectTimeFor === "QUESTION" ? duration / totalQuestions : duration : parseInt(totalMinutes) > parseInt(duration) ? selectTimeFor === "QUESTION" ? duration / totalQuestions : duration : totalMinutes / totalQuestions
    //change document name as per quiz name.
    useEffect(() => {
        fullScreenMode();
        if (name) {
            document.title = name;
        }
    }, []);

    function goToAllTest() {
        history.push('/tests/result/' + quiz.id);
    }

    return (
        !isLoading ?
            <Box className={headerClass}>

                {viewMode ?
                    <Box className={classes.solutionMode} onClick={goToAllTest}>
                        <ArrowBackIcon/>
                        <span>Report Card</span>
                    </Box> : null}
                <Box onClick={toggleDrawerHandler}
                     className={classes.menuIcon + ' ' + (!drawerToggle ? classes.changeColor : classes.menuIconExpand)}>

                    <img alt='question quiz' src={QuestionQuiz}/>
                </Box>
                
                <Box className={classes.mobileView}>{name}</Box>

                <Box onClick={() => handleQuizPauseChange(!pauseQuiz)}
                    className={classes.showMobile}
                    id="playPauseIcon">

                    {pauseQuiz ?
                        <img src={mobilePause} alt='play quiz'/>
                        :
                        <img src={mobilePause} alt='pause quiz'/>
                    }
                </Box>

                {!viewMode ? <QuizTimer viewMode={viewMode}
                                        mode={mode}
                                        setTimeTaken={setTimeTaken}
                                        submitQuiz={submitQuiz}
                                        duration={duration}                                
                                        usedTime={usedTime}
                                        pauseQuiz={pauseQuiz}
                                        difference={difference}
                                        handleClick={handleClick}
                                        selectTimeFor={selectTimeFor}
                                        hideNext={hideNext}
                                        reset={reset}
                                        setReset={setReset}
                                        totalMinutes={totalMinutes}
                                        slug={slug}
                                        newTime={newTime}/>
                    : null
                }

                <Box className={classes.title}>{name}</Box>

                <Box onClick={() => handleQuizPauseChange(!pauseQuiz)}
                    className={classes.showTab}
                    id="playPauseIcon">

                    {pauseQuiz ?
                    <img src={mobilePause} alt='play quiz'/>
                        :
                        <img src={mobilePause} alt='pause quiz'/>
                    }
                </Box>

                {quizPage ?
                    <Box>

                        <Box className="notification-bar quiz-notification-bar">
                            <ul className="right-menus">
                                {mode !== 'live-test' && !viewMode ?                                   
                                    <li onClick={() => handleQuizPauseChange(!pauseQuiz)}
                                        className={classes.overrideHoverEffect + ' ' + classes.hideElementForMobile}
                                        id="playPauseIcon">

                                        {pauseQuiz ?
                                            <img src={pauseImage} alt="pause quiz" className={classes.pauseIcon}/>
                                            :
                                            <img src={pauseImage} alt="resume quiz" className={classes.pauseIcon}/>
                                        }
                                    </li>
                                    : ''}
                                <li className={classes.overrideHoverEffect}>
                                    <SubmitButton slug={slug} mode={mode} hideSubmitButton={true} viewMode={viewMode}
                                                  submitQuiz={submitQuiz} quizId={quiz.id}/>
                                </li>
                            </ul>
                        </Box>
                    </Box> : ''}
            </Box> : 
            <Box className={classes.quizHeader + ' ' + classes.quizLoader}>
                <QuizHeaderLoader/>
            </Box>
    );
}

const styles = theme => ({
    quizHeader: {
        backgroundColor: '#ffffff',
        borderBottom: '5px solid #0C3450',
        height: 66,
        marginBottom: 0,
        position: 'fixed',
        top: 0,
        left: '320px',
        alignItems: 'center',
        right: 0,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 767px)': {
            height: 55,
            alignItems: 'center',
            zIndex: 9999,
            borderBottom: 0,
            borderTop: '5px solid #0C3450',
            left: 0,
            justifyContent: 'space-around',
        },
        '@media (min-width: 768px) and (max-width: 800px)': {
            left: "9.7%",
            transition: '0.3s ease-in-out',
            zIndex: 'inherit',
            borderTop: '5px solid #0C3450',
            borderBottom: 0,
        },
        '@media (min-width: 801px) and (max-width: 1024px)': {
            left: "50px",
            transition: '0.3s ease-in-out',
        },
    },
    quizHeaderExpand: {
        backgroundColor: '#ffffff',
        borderBottom: '5px solid #0C3450',
        height: 66,
        marginBottom: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'space-around',
        '@media (max-width: 800px)': {
            height: 55,
            alignItems: 'center',
            zIndex: 9999,
            borderTop: '5px solid #0C3450',
            borderBottom: '0px',
        },
        '@media (min-width: 801px) and (max-width: 1023px)': {
            left: "320px",
            transition: '0.3s ease-in-out',
            zIndex: 'inherit'
        },
        '@media (min-width: 1024px) and (max-width: 1024px)': {
            left: 300,
            transition: '0.3s ease-in-out',
            zIndex: 'inherit'
        },
        '@media (min-width: 1025px)': {
            left: "30px",
            transition: '0.3s ease-in-out',
            zIndex: 'inherit'
        },
    },
    overrideHoverEffect: {
        '&:hover': {
            background: 'none !important'
        }
    },
    logo: {
        float: 'left',
        '& img': {
            width: '100%',
            maxWidth: 150,
            padding: '10px'
        },
        '@media (max-width:767px)': {
            display: 'none',
            justifyContent: 'flex-start'
        }
    },
    title: {
        float: 'left',
        lineHeight: '66px',
        margin: '0 15px 0 0',
        color: '#0C3450',
        fontSize: 18,
        fontWeight: 500,
        maxWidth: '60%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        overflow: 'hidden',
        '@media (min-width: 280px) and (max-width:320px)': {
            fontSize: "16px",
            margin: "0 16% 0 0",
            display: 'none'
        },
        '@media (min-width: 321px) and (max-width:767px)': {
            fontSize: "16px",
            margin: "0 28% 0 0",
            display: 'none'
        },
        '@media (min-width: 768px) and (max-width:1500px)': {
            fontSize: "18px",
        },
        '@media (min-width: 1501px) and (max-width:1800px)': {
            fontSize: "20px",
        },
        '@media (min-width: 1801px)': {
            fontSize: "24px",
        },
    },

    mobileView: {
        float: 'left',
        lineHeight: '66px',
        margin: '0 15px 0 0',
        color: '#0C3450',
        fontSize: 18,
        fontWeight: 500,
        maxWidth: '60%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        overflow: 'hidden',
        display: 'none',
        '@media (min-width: 280px) and (max-width:767px)': {
            fontSize: "16px",
            margin: "0px 26px 0px 10px",
            display: 'block'
        },
    },
    menuIcon: {
        display: 'none',
        float: 'left',
        '& i': {
            color: '#fff',
            cursor: 'pointer',
            fontSize: 24
        },
        '@media (max-width:767px)': {
            display: 'flex',
        },
        '@media (min-width:768px) and (max-width:800px)': {
            display: 'block',
            position: 'relative',
            top: '23px',
            right: '56px'
        },
    },
    menuIconExpand: {
        display: 'none',
        float: 'left',
        '& i': {
            color: '#fff',
            cursor: 'pointer',
            fontSize: 24
        },
        '@media (max-width:767px)': {
            display: 'flex',
        },
        '@media (min-width:768px) and (max-width:800px)': {
            display: 'contents',
            position: 'relative',
            top: '23px',
            right: '60px'
        },
    },
    questionNumber: {
        display: 'none',
        '@media (max-width:767px)': {
            display: 'block',
            color: '#fff',
            lineHeight: '55px',
            fontSize: 16,
            fontWeight: 'bold',
            left: 55,
            position: 'absolute',
        }
    },
    pauseIcon: {
        position: 'relative',
        top: '4px',
        right: '12px'
    },
    quizLoader: {
        height: '66px'
    },
    hideElementForMobile: {
        display: 'inline-block',
        lineHeight: '8px !important',
        '&#fullscreenMode': {
            background: '#12a188',
            lineHeight: '8px !important',
            '&:hover': {
                background: '#12a188 !important',
            }
        },
        '&#playPauseIcon': {
            lineHeight: '8px !important',
            padding: '4px 6px!important',
            marginRight: 0,
            '& i': {
                marginLeft: 0
            },
            '&:hover': {
                // background: '#8B8787 !important',
            }
        },
        '@media (max-width: 767px)': {
            display: 'none !important'
        }
    },
    solutionMode: {
        display: 'none',
        '@media (max-width: 767px)': {
            display: 'flex',
            alignItems: 'center',
            left: 10,
            position: 'absolute',
            color: '#fff',
            fontSize: 16,
            zIndex: 1,
            '& i': {
                color: '#fff',
                marginRight: 10,
                fontSize: 16
            }
        }
    },
    showMobile: {
        display: 'none',
        '@media (max-width: 767px)': {
            display: 'block',
            alignSelf: 'center'
        },
        
    },
    showTab: {
        display: 'none',
        '@media (min-width: 768px) and (max-width: 1000px)': {
            display: 'block',
            alignSelf: 'center'
        }
    }
});
export default QuizHeader;
