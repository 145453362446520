import React, { useState } from 'react';
import {Modal, Box, DialogContent, Button, Typography} from "@material-ui/core";
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import './feedbackModel.scss';
import { useTranslations } from "examsbook-react-sdk/custom-hooks";

/**
 * FeedbackModel of Main site
 * @param props
 * @returns {*}
 * @constructor
 */
function FeedbackModel(props) {
    const { isFeedbackModelOpen, closeFeedBackModel } = props;
    const t = useTranslations();
    const [ message, setMessage ] = useState('');

    function onTextChange(event) {
        setMessage(event.target.value);
    }

    function onSubmit() {
        props.submitForm(message);
        setMessage('');
        reactGaEvent("Feedback ", "Feedback Submit");
    }

    return (
        <Box>
            <Modal className="feedback-modal result-feedback-modal"
                   aria-labelledby="simple-modal-title"
                   aria-describedby="simple-modal-description"
                   open={isFeedbackModelOpen}>
                <DialogContent>
                    <Box>
                        <Box className="feedback-screen">
                            <Box className="feedback-close" onClick={closeFeedBackModel}>
                                <i className="fa fa-close"/>
                            </Box>
                            <Box className="feedback-heading">
                                <Typography variant='h2'>{t('feedback')}</Typography>
                            </Box>
                            <Box className="feedback-block">
                                    <textarea placeholder={t('Please_Type_Suggestion')} value={message}
                                              onChange={onTextChange}/>
                            </Box>
                            <Box className="feedback-submit">
                                <Button disabled={!message} onClick={onSubmit}>{t('submit_feedback')}</Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Modal>
        </Box>
    );
}

export default FeedbackModel;
