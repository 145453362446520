import React, { useEffect, useState } from 'react';
import './supportTicket.scss';
import {
    Button,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles,
    TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useScreenWidth, useTranslations, useUserSupportTickets } from 'examsbook-react-sdk/custom-hooks';
import OverlayButton from '../../constants/OverlayButton';
import { useSearchQuery } from '../../services/SearchQueryContext';
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/close.svg";
import NotFound from '../NotFound';
import { useTheme } from '../../constants/themeProvider';

const SupportTicket = () => {
    const [ open, setOpen ] = useState({});
    const [ selectedButton, setSelectedButton ] = useState('All');
    const theme = useTheme();
    const t = useTranslations();
    const { searchQuery, setSearchQuery } = useSearchQuery();
    const user = useSelector((state) => state.user);
    let userId = user?._id;
    const { isLoading, tickets } = useUserSupportTickets(userId);
    const screenWidth = useScreenWidth();
    const isFirefox = typeof InstallTrigger !== 'undefined';

    function stripHtml(html) {
        let tmp = document.createElement("p");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText;
    }

    function clearField(searchQuery) {
        if (searchQuery.length) {
            setSearchQuery('');
        }
    };

    function handleChange(id) {
        setOpen(prevOpen => ({ ...prevOpen, [id]: !prevOpen[id] }));
    }

    const history = useHistory();

    function handleSupportForm() {
        history.push('/support');
    }

    const lowerSearchQuery = searchQuery.toLowerCase().trim();
    const filteredSupportData = tickets?.filter((item, index) => {
        const title = item?.subject;
        const titleText = stripHtml(title)?.toLowerCase();
        const lowerTitle = title?.toLowerCase();
        return (
            lowerTitle?.includes(lowerSearchQuery) ||
            titleText?.includes(lowerSearchQuery)
        );
    });
    const filteredSupportList =
        searchQuery && searchQuery.trim() !== ""
            ? filteredSupportData
            : tickets;

    useEffect(() => {
        setSearchQuery('');
        return setSearchQuery('');
    }, []);

    const useStyles = makeStyles(() => ({
        centerAlign: isFirefox => ({
            textAlign: isFirefox ? '-moz-center !important' : '-webkit-center !important',
        }),
        selectedTabSet: {
            color: theme?.secondaryColor,
            textTransform: 'capitalize !important',
            border: '0 !important',
            borderBottom: `1px solid ${theme?.secondaryColor} !important`,
            borderRadius: '0px !important',
        },
        createNew: {
            width: '160px',
            height: '40px',
            fontWeight: '600',
            fontSize: '14px',
            color: '#fff',
            textTransform: 'capitalize',
            '&:hover': {
                backgroundColor: '#0c3450e0 !important',
            },
        },
        "@media (max-width: 549px)": {
            createNew: {
                width: '130px',
            }
        }
    }));
    const classes = useStyles(isFirefox);
    return (
        <Box className="support-box commonShadow">
            <Box className="course-search-box">
                <TextField
                    variant="outlined"
                    placeholder={t('Search')}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event?.target?.value)}
                    fullWidth
                    className="inner-search-box"
                    type="text"
                />
                <Box className="course-search-img-box">
                    {searchQuery?.length ? (
                            <Button onClick={() => clearField(searchQuery)} style={{ border: 'none' }}>
                                <CrossIcon className="cross-icon" alt="Cross Icon"/>
                            </Button>
                        )
                        :
                        (<SearchIcon className="search-icon" alt="Search Icon"/>)
                    }
                </Box>
            </Box>
            <Box className="parent-box">
                {!isLoading && filteredSupportData?.length >=1 && <Box className="tabs-align">
                    <Button
                        variant="outlined"
                        className={selectedButton === 'All' ? classes.selectedTabSet : 'tab-set'}
                        onClick={() => setSelectedButton('All')}
                    >
                        {t('All')}
                    </Button>
                    <Button
                        variant="outlined"
                        className={selectedButton === 'OPEN' ? classes.selectedTabSet : 'tab-set'}
                        onClick={() => setSelectedButton('OPEN')}
                    >
                        {t('Open')}
                    </Button>
                    <Button
                        variant="outlined"
                        className={selectedButton === 'RESOLVED' ? classes.selectedTabSet : 'tab-set'}
                        onClick={() => setSelectedButton('RESOLVED')}
                    >
                        {t('Resolved')}
                    </Button>
                    <Button
                        variant="outlined"
                        className={selectedButton === 'REJECTED' ? classes.selectedTabSet : 'tab-set'}
                        onClick={() => setSelectedButton('REJECTED')}
                    >
                        {t('Rejected')}
                    </Button>
                </Box>}
                <OverlayButton onClick={handleSupportForm} title={"+ " + t('Create New')}
                               classStyleHeading={classes.createNew}/>
            </Box>
            <TableContainer component={Paper} className="support-table">
                <Table className="table">
                    {filteredSupportData?.length > 0 && <TableHead className="heading-bg">
                        <TableRow className="table-row-heading">
                            <TableCell className="support-heading-color support-align">{t('Serial No')}</TableCell>
                            <TableCell className="support-heading-color support-align">#{t('Ticket')}</TableCell>
                            <TableCell className="support-heading-color support-align">{t('Subject')}</TableCell>
                            <TableCell className="support-heading-color support-align">{t('Status')}</TableCell>
                            {screenWidth > 650 && (
                                <>
                                    <TableCell
                                        className="support-heading-color support-align">{t('Open Date')}</TableCell>
                                    <TableCell
                                        className="support-heading-color support-align">{t('Close Date')}</TableCell>
                                </>
                            )}
                            <TableCell className="support-heading-color support-align">{t('Action')}</TableCell>
                        </TableRow>
                    </TableHead>}
                    <TableBody>
                        {filteredSupportList?.filter(tickets => selectedButton === 'All' || tickets.status === selectedButton).length > 0 ? (
                            filteredSupportList?.filter(tickets => selectedButton === 'All' || tickets.status === selectedButton)
                                .sort((a, b) => new Date(b?.updated?.on) - new Date(a?.updated?.on))
                                .map((tickets, index) => (
                                    <React.Fragment key={tickets?._id}>
                                        <TableRow key={tickets?._id} onClick={() => handleChange(tickets?._id)}
                                                  className="row-pointer">
                                            <TableCell
                                                style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                className="support-id-color mobile-view-cell-size">{'#' + index < 9 ? `0${index + 1}` : index + 1}</TableCell>
                                            <TableCell
                                                style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                className="support-id-color mobile-view-cell-size">{tickets?.ticketId}</TableCell>
                                            <TableCell
                                                style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                className="support-items">{tickets?.subject}</TableCell>
                                            <TableCell
                                                style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                className="support-items">
                                                <Box style={{
                                                    borderRadius: '6px',
                                                    background: tickets?.status === 'RESOLVED' ? 'rgba(0, 149, 128, 0.1)' : tickets?.status === 'REJECTED' ? '#ff000026' : tickets?.status === 'OPEN' ? 'rgba(255, 166, 27, 0.1)' : '#FFA61B1A'
                                                }}>
                                                    <Box style={{
                                                        color: tickets?.status === 'RESOLVED' ? '#009580' : tickets?.status === 'REJECTED' ? 'red' : tickets?.status === 'OPEN' ? 'rgba(255, 166, 27, 1)' : '#FFA61B'
                                                    }} className="text-status">
                                                        {tickets?.status}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            {screenWidth > 650 && (
                                                <>
                                                    <TableCell
                                                        style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                        className="support-items">{moment(tickets?.created?.on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                                    <TableCell
                                                        style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                        className="support-items">{tickets?.status === 'OPEN' ? '-' : moment(tickets?.updated?.on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                                </>
                                            )}
                                            <TableCell
                                                style={open[tickets?._id] ? { borderBottom: 'none' } : { borderBottom: '1px solid #e0e0e0' }}
                                                className="accordian-icon support-items">
                                                <Button>
                                                    <ExpandMoreIcon
                                                        style={{ transform: open[tickets?._id] ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        {open[tickets?._id] &&
                                            <TableRow>
                                                <TableCell colSpan={8} className="support-items">
                                                    <Box className="mobile-view-support">
                                                        <Box sx={{ my: 2 }}>
                                                            <Typography variant="body1"
                                                                        className="description-heading">{t('Email')}</Typography>
                                                            <Typography variant="body2"
                                                                        className="description-text">{tickets?.email}</Typography>
                                                        </Box>
                                                        {tickets?.serviceMessage && <Box sx={{ my: 2 }}>
                                                            <Typography variant="body1"
                                                                        className="description-heading">{t('Service message')}</Typography>
                                                            <Typography variant="body2"
                                                                        className="description-text">{tickets?.serviceMessage}</Typography>
                                                        </Box>}
                                                        <Box sx={{ my: 2 }}>
                                                            <Typography variant="body1"
                                                                        className="description-heading">{t('Description')}</Typography>
                                                            <Typography variant="body2"
                                                                        className="description-text">{tickets?.message}</Typography>
                                                        </Box>
                                                        {screenWidth <= 650 &&
                                                            <>
                                                                <Box sx={{ my: 2 }}>
                                                                    <Typography variant="body1"
                                                                                className="description-heading">{t('Open Date')}</Typography>
                                                                    <Typography variant="body2"
                                                                                className="description-text">{moment(tickets?.created?.on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss")}</Typography>
                                                                </Box>
                                                                <Box sx={{ my: 2 }}>
                                                                    <Typography variant="body1"
                                                                                className="description-heading">{t('Close Date')}</Typography>
                                                                    <Typography variant="body2"
                                                                                className="description-text">{tickets?.status === 'OPEN' ? '-' : moment(tickets?.updated?.on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss")}</Typography>
                                                                </Box>
                                                            </>
                                                        }
                                                        {tickets?.adminResponse &&
                                                            <Box sx={{ my: 2 }} className="admin-box">
                                                                <Box component="i" class="fa fa-exclamation-triangle"
                                                                     style={{
                                                                         display: 'block',
                                                                         position: 'absolute',
                                                                         padding: '4px',
                                                                         color: '#0C3450'
                                                                     }}></Box>
                                                                <Typography variant="body1"
                                                                            className="description-heading"
                                                                            style={{ paddingLeft: '28px' }}>{t('Admin message')}</Typography>
                                                                <Typography variant="body2"
                                                                            className="admin-description">{tickets?.adminResponse}</Typography>
                                                            </Box>}
                                                        {tickets?.file && (tickets?.file.startsWith('data:application/pdf') ?
                                                                <>
                                                                    <Box sx={{ my: 2, float: 'left' }}>
                                                                        <Typography variant="body1"
                                                                                    className="description-heading">{t('Attachment')}</Typography>
                                                                        <object data={tickets?.file}
                                                                                type="application/pdf" width="100%"
                                                                                maxHeight="200px">
                                                                            <embed src={tickets?.file}
                                                                                   type="application/pdf"/>
                                                                        </object>
                                                                    </Box>
                                                                </>
                                                                :
                                                                (
                                                                    <>
                                                                        <Typography variant="body1"
                                                                                    className="description-heading">{t('Attachment')}</Typography>
                                                                        {tickets?.file.startsWith('data:image') &&
                                                                            <Box component="img"
                                                                                 className="support-image-preview"
                                                                                 src={tickets?.file}/>}
                                                                    </>
                                                                )
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </React.Fragment>
                                ))
                        ) : !isLoading && filteredSupportList?.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={8} className={classes.centerAlign}
                                           style={{ background: 'transparent' }}>
                                    <NotFound/>
                                </TableCell>
                            </TableRow>
                        ) : !isLoading && (
                            <TableRow>
                                <TableCell colSpan={8} className={classes.centerAlign}
                                           style={{ background: 'transparent' }}>
                                    <Typography style={{ fontSize: '14px' }}>{t('No record found')}</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SupportTicket;
