import React from 'react';
import './../QuestionBox/questionBox.css';
import baselineCloud from '../../../assets/images/baseline-cloud.png';
import { reactGaEvent } from "examsbook-react-sdk/actions/Common";
import { CNavLink } from "../../common/Header/c/CHeader";
import useTranslations from "examsbook-react-sdk/custom-hooks/useTranslations";
import { Box, Typography } from '@material-ui/core';

function QuizError() {
    const t = useTranslations();
    return (
        <Box className="quiz-error">
            <Box className="quiz-error-block">

                <Box className="quiz-error-img">
                    <Box component="img" src={baselineCloud} alt=""/>
                </Box>
                <Box className="quiz-error-text">
                    <Typography variant='h4'>We have notified our awesome team. We will sort out this issue soon.</Typography>
                </Box>
                <Box className="quiz-error-btn" onClick={reactGaEvent("Test Error", "Go To Tests")}>
                    <CNavLink to="/dashboard">
                        {t('Go to Tests')}
                    </CNavLink>
                </Box>
            </Box>
        </Box>
    );
}

export default QuizError;
