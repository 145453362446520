import React from "react";
import { Box } from "@material-ui/core";
import { useStyles, useTranslations } from "examsbook-react-sdk/custom-hooks";
import {
  DashboardOutlined,
  AccountCircleOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";
import { useLocation, useHistory } from "react-router-dom";

export const BottomNavigationTabs = () => {
  const location = useLocation();
  const t = useTranslations();
  const history = useHistory();
  const styles = {
    bottomTab: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      background: "#FFF",
      boxShadow: "0px -2px 12px 0px rgba(225, 225, 225, 0.50)",
      zIndex: 999,
      display: "none",
      padding: '0 20px',
      "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        "& li": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          whiteSpace: "nowrap",
          minWidth: 70,
          padding: "10px 0",
          position: 'relative',
          "& svg": {
            color: "rgba(0, 0, 0, 0.60)",
            width: 24,
            height: 24,
          },
          "& span": {
            color: "rgba(0, 0, 0, 0.60)",
            fontSize: 10,
            paddingTop: 10,
          },
          '&.active': {
            '&::before': {
              content: `''`,
              borderRadius: '0px 0px 3px 3px',
              height: 4,
              width: '100%',
              top: 0,
              background: '#0C3450',
              position: 'absolute'
            },
            '& svg': {
                color: '#0C3450',
            },
            "& span": {
                color: '#0C3450',
                fontWeight: 600
            }      
          }
        },
      },
      "@media (max-width: 767px)": {
        display: "block",
      },
    },
  };
  const classes = useStyles(styles)();
  const routeList = [
    {
      icon: <DashboardOutlined />,
      title: t('dashboard'),
      path: "/dashboard",
    },
    {
      icon: <AssignmentOutlined />,
      title: t('MY_COURSES'),
      path: "/my-courses",
    },
    {
      icon: <AccountCircleOutlined />,
      title: t('Profile'),
      path: "/my-profile",
    },
  ];
  return (
    <Box className={classes.bottomTab} style={{ display: location &&
      location.pathname &&
      location.pathname.includes("/quiz") || location.pathname.includes("/course-material/content") && 'none' }}>
      <ul>
        {routeList.map((items, i) => (
          <li
            key={i}
            className={location.pathname === items.path ? "active" : ""}
            onClick={() => history.push(items.path)}
          >
            {items.icon}
            <span>{items.title}</span>
          </li>
        ))}
      </ul>
    </Box>
  );
};
